import React from 'react'
import { styled } from '@mui/system';
import { Button,Grid, Typography} from '@mui/material';
import Image from 'mui-image'
import {useMediaQuery} from '@mui/material';
import Header from './Header';
import { motion } from 'framer-motion';
import Footer from './Footer';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect, useState } from 'react';
import 'typeface-inter';
import { Link, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slush from './Image/jumpslush.jpg';
import Slush1 from './Image/1.png';
import Slush2 from './Image/2.png';
import Slush3 from './Image/3.png';
import Slush4 from './Image/4.png';
import Slush5 from './Image/Shakuni.png';
import Slush6 from './Image/5.png'
import Slush7 from './Image/6.png'
import Slush8 from './Image/Kaliyuga.png'
import Slush9 from './Image/7.png'
import Slush10 from './Image/8.png'
import Slush11 from './Image/9.png'
import Slush12 from './Image/10.png'
import Slush13 from './Image/11.png'
import { LoginSocialFacebook,LoginSocialGoogle } from 'reactjs-social-login'
import {FacebookLoginButton,GoogleLoginButton} from 'react-social-login-buttons'
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import { IconButton } from '@mui/material';
import { Helmet } from 'react-helmet';
import ImageSlider from './Slider';
import './loading.css';
import Mobileheader from './Mobileheader';
const StyledImage = styled(Image)(({ theme }) => ({
  '& .mui-image-wrapper': {
   backgroundColor:'red',

  },
}));
const CustomButton = styled(Button)`
  &:hover {
    background-color: #086266; /* or specify the desired background color */
  }
`;
const CustomButton1 = styled(Button)`
  &:hover {
    background-color: #F4F1F1; /* or specify the desired background color */
  }
`;

function Landingpage() {
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorEl2, setArchorEl2]=useState(null)
  const mobile = useMediaQuery('(max-width:600px)');
	const navigate = useNavigate();
	const token = localStorage.getItem('token');
	const [open, setOpen] = useState(false);
	const [open1, setOpen1] = useState(false);
	const handleSignup = () => { 
		navigate('/signup')
	}
  const handleClose = () => {
    setAnchorEl(null);
   
	};
	const Gotopage = () => { 
		navigate('/signup')
	}
	const Gotopage1 = () => { 
		navigate('/login')
	}
	const handleClose2 = () => {
		setArchorEl2(null);
	   
		};
		const Gotopage2 = () => { 
			navigate('/signup', { state: { fromReaderup: true } })
		}
		const Gotopage3 = () => { 
			navigate('/login', { state: { fromReaderup: true } })
		}
  const handleClosepopup =() =>{
    setOpen(false)
    // console.log('yess')
	}
	const handleClosepopup1 =() =>{
		setOpen1(false)
		// console.log('yess')
		}
	useEffect(() => {
		window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
	  }, []);
  return (
	  <>
		  <Helmet>
		  <meta name="title" content="Jump the Slush at Slushie - Upload and Publish Your Manuscript with Ease" />
     
         
        <meta name="description" content="Join Slushie, the premier platform for authors, and effortlessly upload, share, and publish your manuscripts. Take the next step in your writing journey and jump the slush with us today." />
      </Helmet>
 {mobile?<Mobileheader/>:<Header/>}
     <Grid container lg={12}  sx={{marginTop:mobile?'39px':'56px'}}  >
						<Grid container lg={10} xs={12} sx={{margin:'auto'}}>
              <Grid container lg={7} xs={9.5} sx={{margin:mobile?'auto':''}}>
							<Grid item  lg={12} sx={{margin:mobile?'':''}} >
							<Typography  sx={{ fontWeight:mobile?'800': '800', fontSize:mobile?'32px': '61px', color:'#1E1E1E',  lineHeight:mobile?'normal': '77px', fontFamily:mobile?'Inter':'Inter',fontStyle:'normal'}}>
              Discover {mobile?'':<br/>}
the{mobile?<br/>:""} Next Bestseller!
</Typography>
					</Grid>
							<Grid item  lg={12}  >
						
								
										<Grid item  lg={9.5} sx={{marginTop:'36px'}} >
							<Typography  sx={{ fontWeight:mobile?'400': '400', fontSize: mobile?'14px':'20px',fontFamily:mobile?'Inter':'Inter',fontStyle:'normal',lineHeight: '130%'}}>4 Million books are published annually but these
are only 1% of manuscripts that are submitted for publication.
	</Typography>
							</Grid>
              <Grid item lg={11}sx={{marginTop:'16px'}} >
									<Typography  sx={{ fontWeight: '400', fontSize:mobile?'14px': '20px',  fontFamily:'Inter',fontStyle:'normal',lineHeight: '130%'  }}>We help you <span style={{fontWeight:'600'}}>escape that slush pile</span> as we believe there is no such thing as a bad story.</Typography>
								</Grid>
								<Grid item lg={10.5}sx={{marginTop:'16px'}} >
									<Typography  sx={{ fontWeight: '400', fontSize:mobile?'14px': '20px',  fontFamily:'Inter',fontStyle:'normal',lineHeight: '130%'  }}>Choose a story that you want to read from a pool of manuscripts and make it your <span style={{fontWeight:'600'}}>Next Bestseller.</span> </Typography>
								</Grid>
								
		
					  </Grid>	
					  {token ?"":
					  	 <Grid container direction="row"  lg={8} xs={10}  sx={{marginTop:'32px'}}>
  <Grid item lg={5.8} xs={6} >
							  <CustomButton onClick={() => setOpen1(true)} variant='contained' sx={{ borderRadius: '8px', textTransform: 'none',fontFamily:'Inter' ,fontSize:mobile?'12px': '20px', fontWeight: "500", lineHeight: "24px", width:mobile?'100px':  '188px', height:mobile?'36px': "54px" , backgroundColor: '#007F85', color: '#fff',fontStyle:'normal' }}>
      Reader
							  </CustomButton>
							  <Dialog open={open} PaperProps={{
										style: {
										  display: "flex",
										  justifyContent: "flex-end",
                      width: "382px", 
      height: "455px",borderRadius:'26px'
										  
										}
									  }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
								>
							
        
              <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' , fontSize: '26px',fontWeight:'700',fontFamily:'Inter', color: '#333333',marginTop: '20px' }}>
      {"Join Slushie"}
      <IconButton onClick={handleClosepopup} color="primary" style={{ position: 'absolute', right: 20, top: 20 }}>
        <CloseIcon style={{ color: '#1e1e1e' }} />
      </IconButton>
    </DialogTitle>
    <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' ,fontSize: '12px',fontWeight:'500',fontFamily:'Inter',marginTop: '-25px'  }}>
      Choose your next  bestseller <br /> with just one click
    </DialogTitle>
          <DialogContent style={{overflow:'clip'}}>
          <Grid container lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
										  <Grid item lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
											  
		
											  <LoginSocialFacebook
												 
		appId="305111921952755"
		onResolve={(response) => {
			// console.log(response);
			// console.log(response.data);
					  
		
			localStorage.setItem('user', response.data.name);
			localStorage.setItem('useremail', response.data.email);
		// console.log(response.data, 'kiki');
		navigate('/Entries')
		
		}}
		onReject={(error) => {
		  console.log(error);
		}}
	  >
		<FacebookLoginButton  text="Sign up with Facebook" style={{borderRadius:'36px',fontSize:'16px',fontFamily:'Inter',fontWeight:500,width:'271px',height:'46px'}} />
										  </LoginSocialFacebook>
										  </Grid>
										  <Grid item lg={12} style={{ display: 'flex', justifyContent: 'center',marginTop:'8px' }}>
										  <LoginSocialGoogle client_id='110556347145-1sehcpdghgh801c1jgb62s02ns8f2a4k.apps.googleusercontent.com'
				  onResolve={(response) => {
					//   console.log(response.data);
					  
		
					  localStorage.setItem('user', response.data.name);
					  localStorage.setItem('useremail', response.data.email);
		// console.log(response.data, 'kiki');
		navigate('/Entries')
					
				  }}
				  onReject={(error) => {
					console.log(error);
				  }}>
					  <GoogleLoginButton text="Sign up with Google" style={{borderRadius:'36px',fontSize:'16px',fontFamily:'Inter',fontWeight:500,width:'271px',height:'46px'}}/>
											  </LoginSocialGoogle>
											  </Grid>
    </Grid>
  
    
        <Grid item lg={12} style={{ display: 'flex', justifyContent: 'center',marginTop:'8px' }}>
      <Typography variant="body1" style={{ fontSize: '12px',fontFamily:'Inter', color: '#333333' }}>
        OR
      </Typography>
    </Grid>
        <Grid item lg={12} style={{ display: 'flex', justifyContent: 'center',marginTop:'8px' }}>
        <Button
      variant="contained"
      color="primary"
											  onClick={Gotopage}
      style={{ borderRadius: '36px', backgroundColor: '#000000',textTransform: 'none',fontSize:'16px',fontFamily:'Inter',fontWeight:600,width:'271px',height:'46px' }}
    >
      Sign up with Email
    </Button>
        </Grid>
        <Grid item lg={12} sx={{textAlign:'center',display:'flex',justifyContent:'center',marginTop:'12px'}}>
       
		<Typography onClick={Gotopage1 } sx={{fontSize: '12px',fontFamily:'Inter', color: '#333333',fontWeight:'500',color:'#5B5A5A'}}> Already have an account? <span style={{ color: '#1e1e1e',fontWeight:'600',fontFamily:'Inter',fontSize:'12px' }}>Login</span></Typography>
        </Grid>
        <Grid item lg={12} sx={{textAlign:'center',display:'flex',justifyContent:'center',marginTop:'26px'}}>
          <Typography style={{ fontSize: '12px',fontFamily:'Inter', color: '#333333',fontWeight:'500' }}>By continuing, you agree to our <span style={{ color: '#1e1e1e',fontWeight:'600',fontFamily:'Inter',fontSize:'12px' }}>Terms of <br/> Service</span>   and <span style={{ color: '#1e1e1e',fontWeight:'600',fontFamily:'Inter',fontSize:'12px' }}>Privacy Policy.</span></Typography>
        </Grid>
      </DialogContent>
          <DialogActions style={{ justifyContent: "space-between"}}>
										
          
			
						
          </DialogActions>
								  </Dialog>
								  <Dialog open={open1} PaperProps={{
										style: {
										  display: "flex",
										  justifyContent: "flex-end",
                      width: "382px", 
      height: "455px",borderRadius:'26px'
										  
										}
									  }}
        onClose={handleClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
								>
							
        
              <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' , fontSize: '26px',fontWeight:'700',fontFamily:'Inter', color: '#333333',marginTop: '20px' }}>
      {"Join Slushie"}
      <IconButton onClick={handleClosepopup1} color="primary" style={{ position: 'absolute', right: 20, top: 20 }}>
        <CloseIcon style={{ color: '#1e1e1e' }} />
      </IconButton>
    </DialogTitle>
    <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' ,fontSize: '12px',fontWeight:'500',fontFamily:'Inter',marginTop: '-25px'  }}>
      Choose your next  bestseller <br /> with just one click
    </DialogTitle>
          <DialogContent style={{overflow:'clip'}}>
          <Grid container lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
										  <Grid item lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
											  
		
											  <LoginSocialFacebook
												 
		appId="305111921952755"
		onResolve={(response) => {
			// console.log(response);
			// console.log(response.data);
					  
		
			localStorage.setItem('user', response.data.name);
			localStorage.setItem('useremail', response.data.email);
		// console.log(response.data, 'kiki');
		navigate('/Entries', { state: { fromReaderup: true } })
		
		}}
		onReject={(error) => {
		  console.log(error);
		}}
	  >
		<FacebookLoginButton  text="Sign up with Facebook" style={{borderRadius:'36px',fontSize:'16px',fontFamily:'Inter',fontWeight:500,width:'271px',height:'46px'}} />
										  </LoginSocialFacebook>
										  </Grid>
										  <Grid item lg={12} style={{ display: 'flex', justifyContent: 'center',marginTop:'8px' }}>
										  <LoginSocialGoogle client_id='110556347145-1sehcpdghgh801c1jgb62s02ns8f2a4k.apps.googleusercontent.com'
				  onResolve={(response) => {
					//   console.log(response.data);
					  
		
					  localStorage.setItem('user', response.data.name);
					  localStorage.setItem('useremail', response.data.email);
		// console.log(response.data, 'kiki');
		navigate('/Entries', { state: { fromReaderup: true } })
					
				  }}
				  onReject={(error) => {
					console.log(error);
				  }}>
					  <GoogleLoginButton text="Sign up with Google" style={{borderRadius:'36px',fontSize:'16px',fontFamily:'Inter',fontWeight:500,width:'271px',height:'46px'}}/>
											  </LoginSocialGoogle>
											  </Grid>
    </Grid>
  
    
        <Grid item lg={12} style={{ display: 'flex', justifyContent: 'center',marginTop:'8px' }}>
      <Typography variant="body1" style={{ fontSize: '12px',fontFamily:'Inter', color: '#333333' }}>
        OR
      </Typography>
    </Grid>
        <Grid item lg={12} style={{ display: 'flex', justifyContent: 'center',marginTop:'8px' }}>
        <Button
      variant="contained"
      color="primary"
											  onClick={Gotopage2}
      style={{ borderRadius: '36px', backgroundColor: '#000000',textTransform: 'none',fontSize:'16px',fontFamily:'Inter',fontWeight:600,width:'271px',height:'46px' }}
    >
      Sign up with Email
    </Button>
        </Grid>
        <Grid item lg={12} sx={{textAlign:'center',display:'flex',justifyContent:'center',marginTop:'12px'}}>
       
		<Typography onClick={Gotopage3 } sx={{fontSize: '12px',fontFamily:'Inter', color: '#333333',fontWeight:'500',color:'#5B5A5A'}}> Already have an account? <span style={{ color: '#1e1e1e',fontWeight:'600',fontFamily:'Inter',fontSize:'12px' }}>Login</span></Typography>
        </Grid>
        <Grid item lg={12} sx={{textAlign:'center',display:'flex',justifyContent:'center',marginTop:'26px'}}>
          <Typography style={{ fontSize: '12px',fontFamily:'Inter', color: '#333333',fontWeight:'500' }}>By continuing, you agree to our <span style={{ color: '#1e1e1e',fontWeight:'600',fontFamily:'Inter',fontSize:'12px' }}>Terms of <br/> Service</span>   and <span style={{ color: '#1e1e1e',fontWeight:'600',fontFamily:'Inter',fontSize:'12px' }}>Privacy Policy.</span></Typography>
        </Grid>
      </DialogContent>
          <DialogActions style={{ justifyContent: "space-between"}}>
										
          
			
						
          </DialogActions>
          </Dialog>
  </Grid>	
  <Grid item lg={5.8} xs={6} >
    <CustomButton onClick={() => setOpen(true)}variant='contained' sx={{ borderRadius: '8px', textTransform: 'none',fontFamily:'Inter', fontSize:mobile?'12px': '20px', fontWeight: "500", lineHeight: "24px", width: mobile?'100px': '188px', height:mobile?'36px': "54px", backgroundColor: '#007F85', color: '#fff',fontStyle:'normal' }}>
      Writer
							  </CustomButton>
						
  </Grid>
</Grid>  }			  
				
</Grid>
<Grid container lg={5} xs={12}  >
							<Grid item  lg={12}  xs={12} sx={{marginTop:'-80px'}} >	
								
								
             
      {/* <Image
        duration={0}
        src="https://drive.google.com/uc?export=view&id=12Krz59BrmVX6APRgDE5YzM6vck2zHWwS"
        style={{ width: '456px', height: '456px', transitionDuration: '0', animation: '0', position: 'absolute', zIndex: 0 }}
      /> */}
                
                <Image
  duration={0}
  src={Slush}
  style={{
    width:mobile?"290px":'426px',
    height:mobile?'290px': '426px',
    marginTop: mobile ? '156px' : '64px',
    transitionDuration: '0',
    animation: '0',
    zIndex: 1,
    borderRadius: '50%',
  // To maintain the aspect ratio of the image within the circle
  }}
/>
      
   
									
									
</Grid>			
						
				</Grid>
			
        <Grid container lg={12} xs={9}  sx={{margin:'auto', backgroundColor:'#DAFDF5' ,width:mobile?'272px':'1140px',height:mobile?'416px':'256px', borderRadius:'26px',marginTop:mobile?'67px':'147px'}}>
				  <Grid container lg={12} >
           <Grid item lg={12} sx={{margin:'auto'}} >
				   <Typography sx={{ textAlign: 'center',color:'#1E1E1E',fontFamily:'Inter' ,fontSize:mobile?'12px': '24px', fontWeight: "500", }}>Your manuscript can</Typography>
			</Grid>
				   
			<Grid container lg={10} xs={12} sx={{marginTop:'25px',margin:'auto'}}>   
				   
  <Grid container lg={4}  >
 
  <Grid item lg={12} xs={12} >
    <Image
      duration={0}
      src={Slush1}
      style={{
       
        width:mobile?'46px': '60px',
        height:mobile?'46px': '60px',
        transitionDuration: '0',
        animation: '0',
      }}
    />
    </Grid>
    <Grid item lg={12} xs={12} sx={{marginTop:'12px'}}>
    <Typography  sx={{fontFamily:'Inter' ,fontSize:mobile?'14px': '24px', fontWeight: "600",textAlign:'center' }}>
    Get 100% Publishing<br/> Assistance
    </Typography>
  
    </Grid>

     
  </Grid>
  
  <Grid container lg={4}  sx={{marginTop:mobile?'26px':'-19px'}}>
  <Grid item lg={12}  xs={12}>
  <Image
    duration={0}
    src={Slush2}
    style={{
   
      width:mobile?'46px': '60px',
      height:mobile?'46px': '60px',
      transitionDuration: '0',
      animation: '0',
    }}

  />
  </Grid>
  <Grid item lg={12} xs={12} sx={{margintop:mobile?'12px':'0px'}}>
 <Typography  sx={{fontFamily:'Inter' ,fontSize:mobile?'14px': '24px', fontWeight: "600",textAlign:'center' }}>
 Get Published
    </Typography>
</Grid>
</Grid>
<Grid container lg={4} sx={{marginTop:mobile?'26px':'0px'}} >
  <Grid item lg={12} xs={12}>
  <Image
    duration={0}
    src={Slush3}
    style={{
   
      width: mobile?'46px':'60px',
      height: mobile?'46px':'60px',
      transitionDuration: '0',
      animation: '0',
    }}

  />
  </Grid>
  <Grid item lg={12} xs={12} sx={{marginTop:'12px'}}>
 <Typography  sx={{fontFamily:mobile?'Inter':'Inter' ,fontSize:mobile?'14px': '24px', fontWeight:mobile?'600': "600",textAlign:'center' }}>
 Get Produced<br/>
to movies/ series
    </Typography>
</Grid>
</Grid>
</Grid>
</Grid>
</Grid>	

{mobile?<Grid container lg={6} xs={9} sx={{margin:mobile?'auto':'',}}  >
  <Grid item lg={10} xs={12} sx={{marginTop:mobile?'35px':'0px'}}>
    <Typography sx={{textAlign:"initial", marginTop: '32px', fontFamily: mobile?'Inter': 'Inter', fontSize: mobile?'16px':'26px', fontWeight: mobile?'600': "600", color: '#4CA591' }}>Slushie Bestsellers</Typography>
  </Grid>
  <Grid item lg={10} xs={12}>
    <Typography sx={{textAlign:mobile?'left':'left', fontFamily: mobile?'Inter': 'Inter', fontSize: mobile?'26px': '48px', fontWeight:mobile?'700':"700", color: '#1E1E1E' }}>Shakuni</Typography>
  </Grid>
  <Grid item lg={11.5} xs={10.5} >
    <Typography sx={{textAlign:'left', fontFamily:mobile?'Inter': 'Inter', fontSize:mobile?'14px':'20px', fontWeight: mobile?'400': "400", color: '#1E1E1E',lineHeight:'146%' }}><span style={{fontWeight:'600'}}>A tale of revenge, love and rebirth</span> when an ancient enemy returns to a modern world.  <br/>
    Written by Harsha, published by Nu Voice Press, distributed by Simon and Schuster.<br/> </Typography>
  </Grid>
  <Grid item lg={12} xs={12} >
    <Typography sx={{textAlign:'left', fontFamily: mobile?'Inter': 'Inter', fontSize: mobile?'14px': '16px', fontWeight: mobile?'400': "400", color: '#766A6A' ,marginTop:'12px'}}>Published by:</Typography>
  </Grid>
  <Grid item lg={1.5} xs={10} sx={{ marginTop: '8px', display: mobile?'flex':'', justifyContent: mobile ? 'left' : '',  }}>
  <img
    duration={0}
    src={Slush4}
    style={{
      width: mobile ? '46px' : '63px',
      height: mobile ? '56px' : '77px',
      transitionDuration: '0',
      animation: '0',
      alignItems:'initial',
    }}
  />
</Grid>

</Grid>:''}





<Grid container lg={12} xs={12}>
  <Grid container lg={12} xs={12} sx={{marginTop:mobile?'20px':'166px',justifyContent:mobile?'center':''}}>
    <Grid container lg={6} xs={10}>
<Grid item lg={12} xs={12} sx={{background:mobile?`url(${Slush11})`:`url(${Slush12})`,backgroundPosition:'center',backgroundRepeat:'no-repeat',}}>

<Image
          duration={0}
          src={Slush5}
          style={{ width: mobile?'197px':'317px', height:mobile?'270px': '439px', transitionDuration: '0', animation: '0', zIndex: 1}}
									  />
									 
</Grid>    


    </Grid>
   {mobile?'': <Grid container lg={6} xs={9} >
  <Grid item lg={10} xs={12}>
    <Typography sx={{textAlign:"initial", marginTop: '32px', fontFamily: 'Inter', fontSize: '26px', fontWeight: "600", color: '#4CA591' }}>Slushie Bestsellers</Typography>
  </Grid>
  <Grid item lg={10} xs={12}>
    <Typography sx={{textAlign:'left', fontFamily: 'Inter', fontSize: '48px', fontWeight: "700", color: '#1E1E1E' }}>Shakuni</Typography>
  </Grid>
  <Grid item lg={11} xs={12}>
    <Typography sx={{textAlign:'left', fontFamily: 'Inter', fontSize: '20px', fontWeight: "400", color: '#1E1E1E',lineHeight:'146%' }}> <span style={{fontWeight:'600'}}>A tale of revenge, love and rebirth</span> when an ancient enemy returns to a modern world.<br/>
    Written by Harsha, published by Nu Voice Press, distributed by Simon and Schuster.<br/> </Typography>
  </Grid>
  <Grid item lg={12} xs={12} >
    <Typography sx={{textAlign:'left', fontFamily: 'Inter', fontSize: '16px', fontWeight: "400", color: '#766A6A' ,marginTop:'12px'}}>Published by:</Typography>
  </Grid>
  <Grid item lg={1.5} sx={{marginTop:'8px'}} >
  <Image
    duration={0}
    src={Slush6}
    style={{
   
      width: '63px',
      height: '77px',
      transitionDuration: '0',
      animation: '0',
    }}

  />
  </Grid>
</Grid>}

  </Grid>
</Grid>
<Grid container lg={12} xs={9} sx={{margin:mobile?'auto':'',}} >
  <Grid container lg={12} sx={{marginTop:'75px'}}>
  <Grid container lg={6} sx={{marginTop:mobile?'-60px':"0px"}}>
  <Grid item lg={10} xs={12}>
    <Typography sx={{textAlign:"initial", marginTop: '32px', fontFamily: mobile?'Inter': 'Inter', fontSize: mobile?'16px':'26px', fontWeight: mobile?'600': "600", color: '#4CA591' }}>Slushie Bestsellers</Typography>
  </Grid>
  <Grid item lg={10} xs={12}>
    <Typography sx={{textAlign:mobile?'left':'left', fontFamily: mobile?'Inter': 'Inter', fontSize: mobile?'26px': '48px', fontWeight:mobile?'700':"700", color: '#1E1E1E' }}>Kaliyuga</Typography>
  </Grid>
  <Grid item lg={12} xs={12}>
    <Typography sx={{textAlign:'left', fontFamily:mobile?'Inter': 'Inter', fontSize:mobile?'14px':'20px', fontWeight: mobile?'400': "400", color: '#1E1E1E',lineHeight:'146%' }}><span style={{fontWeight:'600'}}>Mythology, Science and Fantasy</span> come together in a story of the battle between good and evil.<br/>
Written by Harsheath Chauhan, Kaliyuga is published by Nu Voice Press and is distributed by Simon and Schuster.</Typography>
  </Grid>
  <Grid item lg={12} xs={12}>
    <Typography sx={{textAlign:'left', fontFamily: mobile?'Inter': 'Inter', fontSize: mobile?'14px': '16px', fontWeight: mobile?'400': "400", color: '#766A6A' ,marginTop:'12px'}}>Published by:</Typography>
  </Grid>
  <Grid item lg={1.5} xs={10} sx={{ marginTop: '8px', display: mobile?'flex':'', justifyContent: mobile ? 'left' : '',  }} >
  <img
    duration={0}
    src={Slush7}
    style={{
   
      width: '63px',
      height: '77px',
      transitionDuration: '0',
      animation: '0',
    }}

  />
  </Grid>
</Grid>
<Grid container lg={6} xs={12} sx={{marginTop:mobile?'31px':'0px'}}>
<Grid item lg={12}  xs={12} sx={{background:mobile?`url(${Slush11})`:`url(${Slush12})`,backgroundPosition:'center',backgroundRepeat:'no-repeat',}}>

								  <Image
									  duration={0}
									  src={Slush8}
									  style={{ width:mobile?'197px': '317px', height:mobile?'270px': '439px', transitionDuration: '0', animation: '0', zIndex: 1 }}
        />

</Grid>    
 

    </Grid>
  </Grid>
</Grid>

		
<Grid container lg={12} xs={10} sx={{ margin: 'auto', backgroundColor: '#DAFDF5', borderRadius: '26px', marginTop:mobile?'45px': '147px' }}>
  <Grid container lg={10} xs={10} sx={{margin:'auto',marginBottom:'40px'}}>
						  <Grid container lg={12} xs={12}  >
							  <Typography sx={{textAlign: 'initial', marginTop:mobile?'25px': '40px', fontSize:mobile?'14px': '24px', fontWeight:mobile?'500': "500", color: '#1E1E1E', fontFamily:mobile?'Inter': 'Inter' }}>Build a community of readers and get published by the best in the industry</Typography>
    
  </Grid>
 
					 
						  
  <Grid container lg={7}  >
    <Grid item lg={12} sx={{marginTop:mobile?'19px':'56px'}}>
      <img
        duration={0}
        src={Slush9}
        style={{
          width:mobile?'36px': '60px',
          height:mobile?'31px': '60px',
          transitionDuration: '0',
          animation: '0',justifyContent:'start'
        }}
      />
      </Grid>
      <Grid item lg={9.5} xs={12} sx={{marginTop:mobile?'15px':'26px'}}>
      <Typography sx={{ fontFamily:mobile?'Inter': 'Inter', fontSize:mobile?'14px': '20px',  textAlign: 'initial',lineHeight:'139%' }}>
  <span style={{ color: '#1e1e1e',fontWeight:mobile?'600': "600", }}>Readers:</span> Discover free and unlimited fascinating stories across genres. Support your favourite stories and help them get published.
</Typography>

</Grid>
  </Grid>

  <Grid container lg={5}  >
    <Grid item lg={12} sx={{marginTop:mobile?'19px':'56px'}} >
      <img
        duration={0}
        src={Slush10}
        style={{
          width:mobile?'40px': '64px',
          height:mobile?'40px': '64px',
          transitionDuration: '0',
          animation: '0',justifyContent:'start'
        }}
      />
      </Grid>

      <Grid item lg={11.5}  xs={12} sx={{marginTop:mobile?'15px':'26px'}} >
      <Typography sx={{ fontFamily: mobile?'Inter':'Inter', fontSize: mobile?'14px':'20px',  textAlign: 'initial',lineHeight:'139%' }}>
  <span style={{ color: '#1e1e1e',fontWeight:mobile?'600': "600", }}>Writers:</span> Take your manuscript out of the slush pile and to your readers.Build a community of readers and get published.
</Typography>

</Grid>

							  </Grid>
							  
               
</Grid>

</Grid>
</Grid>


<Grid container lg={12} xs={12} sx={{ backgroundColor: '#FAF7F7', marginTop:mobile?'56px': '140px',  }}>
  <Grid container lg={12} xs={12} sx={{ margin:mobile?'0px': 'auto 64px', marginBottom: '64px' }}>
    <Grid item lg={12} xs={12}>
      <Typography sx={{ textAlign: 'center', marginTop: '64px', color: '#1E1E1E', fontFamily:mobile?'Inter': 'Inter', fontSize:mobile?'26px': '48px', fontWeight:mobile?'700': "700" }}>
        How <span style={{ color: '#007F85' }}>Slushie</span> Works
      </Typography>
    </Grid>
    <Grid container lg={11}  xs={10}sx={{ margin: 'auto', marginTop:mobile?'25px': '80px',justifyContent:mobile?'center':'' }}>
      <Grid container lg={4} xs={10} >
        <Grid item lg={2} xs={12} sx={{textAlign:mobile?'center':'initial'}}>
        <Typography sx={{ color: '#007585', fontFamily:mobile?'Inter': 'Inter', fontSize:mobile?'26px': '70px', fontWeight:mobile?'700': "600" }}>1</Typography>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Typography sx={{ fontFamily: mobile?'Inter':'Inter', fontSize:mobile?'20px': '40px', fontWeight: mobile?'600':"600", textAlign: mobile?'center':'initial' }}>
            Sign Up
          </Typography>
        </Grid>
        <Grid item lg={10.7} xs={12} sx={{ textAlign: 'left', width: '369px',}}>
          <Typography sx={{ fontFamily:mobile?'Inter': 'Inter', fontSize: mobile?'14px':'20px', fontWeight:mobile?'400': "400", textAlign:mobile?'center': 'initial' }}>
            Sign up and upload your finished manuscript. After verification, choose 3 chapters that will go live for readers.
            Don’t worry your manuscript is 100% safe with us!
          </Typography>
        </Grid>
      </Grid>

      <Grid container lg={4} xs={10} sx={{marginTop:mobile?'25px':''}} >
         <Grid item lg={2} xs={12} sx={{textAlign:mobile?'center':'initial'}}>
        <Typography  sx={{ color: '#007585', fontFamily:mobile?'Inter': 'Inter', fontSize:mobile?'26px': '70px', fontWeight:mobile?'700': "600" }}>2</Typography>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Typography  sx={{ fontFamily: mobile?'Inter':'Inter', fontSize:mobile?'20px': '40px', fontWeight: mobile?'600':"600", textAlign:mobile?'center': 'initial' }}>
            Get Votes
          </Typography>
        </Grid>
        <Grid item lg={10.7} xs={12} sx={{ textAlign: 'left' ,marginBottom:'32px'}}>
          <Typography sx={{ fontFamily:mobile?'Inter': 'Inter', fontSize: mobile?'14px':'20px', fontWeight:mobile?'400': "400", textAlign:mobile?'center': 'initial' }}>
            Get 500 votes on your manuscript for guaranteed publishing assistance.
            Connect with your audience and leave the rest to us!
          </Typography>
        </Grid>
      </Grid>

      <Grid container lg={4} xs={10} >
        <Grid item lg={2} xs={12} sx={{textAlign:mobile?'center':'initial'}}>
        <Typography sx={{ color: '#007585', fontFamily:mobile?'Inter': 'Inter', fontSize:mobile?'26px': '70px', fontWeight:mobile?'700': "600" }}>3</Typography>
       </Grid>
        <Grid item lg={12} xs={12}>
          <Typography sx={{ fontFamily: mobile?'Inter':'Inter', fontSize:mobile?'20px': '40px', fontWeight: mobile?'600':"600", textAlign:mobile?'center': 'initial' }}>
            Be Published
          </Typography>
        </Grid>
        <Grid item lg={10} xs={12} sx={{ textAlign: 'left' ,marginBottom:'32px'}}>
          <Typography sx={{ fontFamily:mobile?'Inter': 'Inter', fontSize: mobile?'14px':'20px', fontWeight:mobile?'400': "400", textAlign: mobile?'center':'initial' }}>
            Bridge the gap between your manuscript and your readers’ shelves.
            Let’s give your readers their next favorite read!
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
</Grid>



<Grid container lg={12} xs={12} sx={{marginTop:mobile?'27px':'116px',marginBottom:'116px'}}>
  
  <Grid item lg={12} xs={12}>
  <Typography sx={{ textAlign: 'center',marginTop:'48px',color:'#1E1E1E',fontFamily:mobile?'Inter':'Inter' ,fontSize: mobile?'26px':'48px', fontWeight:mobile?'700': "700", }}>Brand And Industry</Typography>
  </Grid>
  <Grid item lg={12} xs={12}>
  <Typography sx={{ textAlign: 'center',marginTop:'0px',color:'#1E1E1E',fontFamily:'Inter' ,fontSize:mobile?'14px': '26px', fontWeight: "400", }}>Authors We Have Worked With</Typography>
				  </Grid>
  <Grid container lg={10} xs={10} sx={{margin:'auto',}} >
				  <ImageSlider />
		
      
				  {/* <Grid item lg={1.7} sx={{marginTop:'63px'}} >
          <Image
          duration={0}
          src="https://drive.google.com/uc?export=view&id=1ALo4GanQJOsZM9XkaXMIQM0Yv5sjo_Tt"
          style={{ width: '72px', height: '90px', transitionDuration: '0', animation: '0', }}
        />
          </Grid> */}
          {/* <Grid item lg={1.7}  sx={{marginTop:'63px'}}>
          <Image
          duration={0}
          src="https://drive.google.com/uc?export=view&id=1rQQSZVoW6QRlotSY5CQgHt-r-GNEkKEX"
          style={{ width: '106px', height: '103px', transitionDuration: '0', animation: '0', }}
        />
</Grid>
<Grid item lg={1.7} sx={{marginTop:'63px'}} >
<Image
          duration={0}
          src="https://drive.google.com/uc?export=view&id=1CTsjPJg3DnjfU8RaTxlmkM1aA_ZSA0vL"
          style={{ width: '106px', height: '103px', transitionDuration: '0', animation: '0', }}
        />
</Grid>
<Grid item lg={1.7}  sx={{marginTop:'63px'}}>
<Image
          duration={0}
          src="https://drive.google.com/uc?export=view&id=1sdd7TzUHvpQA_vWdHk16DdSOYlyHnmzU"
          style={{ width: '106px', height: '103px', transitionDuration: '0', animation: '0', }}
        />
</Grid>
<Grid item lg={1.7} sx={{marginTop:'63px'}} >
<Image
          duration={0}
          src="https://drive.google.com/uc?export=view&id=1vxG9uPSOxvOLuOsSfu2TuCRlTGkDszeR"
          style={{ width: '79px', height: '77px', transitionDuration: '0', animation: '0', }}
        />
</Grid>
<Grid item lg={1.7} sx={{marginTop:'63px'}}>
<Image
          duration={0}
          src="https://drive.google.com/uc?export=view&id=1baDiiZu06dZ5JgiH5vcduuA1WQDOLuZ2"
          style={{ width: '106px', height: '103px', transitionDuration: '0', animation: '0', }}
        />
</Grid>
<Grid item lg={1.7} sx={{marginTop:'63px'}}>
<Image
          duration={0}
          src="https://drive.google.com/uc?export=view&id=1baDiiZu06dZ5JgiH5vcduuA1WQDOLuZ2"
          style={{ width: '106px', height: '103px', transitionDuration: '0', animation: '0', }}
        />
</Grid> */}

</Grid>

</Grid>
<Grid container lg={12} xs={12} sx={{backgroundImage:`url(${Slush13})`,justifyContent:mobile?'center':'',marginTop:mobile?'-147px':''}}>
  <Grid container lg={8} xs={10} sx={{margin:'auto',marginTop:'86px',marginBottom:'86px',textAlign:'center'}}>
<Grid item lg={12} xs={12}>
  <Typography sx={{fontSize:mobile?'26px':'48px',fontWeight:mobile?'700':'700',fontFamily:mobile?'Inter':'Inter',color:'#ffffff',}}>What’s brewing?</Typography>
</Grid>

<Grid item lg={12} xs={12} >
  {mobile? <Typography sx={{fontSize:mobile?'14px':'20px',fontWeight:mobile?'400':'400',fontFamily:mobile?'Inter':'Inter',color:'#ffffff'}}>Welcome to the hotbed of contemporary literature straight from the writer’s desk to the reader’s screens.</Typography>:
  <Typography sx={{fontSize:mobile?'14px':'20px',fontWeight:mobile?'400':'400',fontFamily:mobile?'Inter':'Inter',color:'#ffffff'}}>Welcome to the hotbed of contemporary literature straight <br/>from the writer’s desk to the reader’s screens.</Typography>
}</Grid>
{token?"":<Grid container lg={6}  xs={10} sx={{margin:'auto',marginTop:'30px'}}>
<Grid item lg={6} xs={6}>
    <CustomButton1 onClick={()=>setOpen1(true)}  variant='contained' sx={{ borderRadius:mobile?'8px': '12px', textTransform: 'none',fontFamily:'Inter' ,fontSize:mobile?'12px': '16px', fontWeight:mobile?'500': "500", lineHeight: "24px", width:mobile?'100px':  '180px', height: mobile?'36px':"48px" ,  backgroundColor: '#fff', color: '#007f85',fontStyle:'normal' }}>
      Reader
    </CustomButton1>
	
  </Grid>	
  <Grid item lg={6} xs={6}>
    <CustomButton1  onClick={()=>setOpen(true)} variant='contained' sx={{ borderRadius:mobile?'8px': '12px', textTransform: 'none',fontFamily:'Inter' ,fontSize:mobile?'12px': '16px', fontWeight:mobile?'500': "500", lineHeight: "24px", width:mobile?'100px':  '180px', height:mobile?'36px': "48px" , backgroundColor: '#fff', color: '#007f85',fontStyle:'normal' }}>
   Writer
    </CustomButton1>
    
  </Grid>
  </Grid>}	
  </Grid>
</Grid>
			
			
      </Grid>
      <Footer/>
      </>
  )
}

export default Landingpage