import React, { useState,useEffect } from 'react';
import { Typography, Grid, Paper, TextField, Button } from '@mui/material'
import Image from 'mui-image'
import W1 from './Image/W1.png'
import Foo from './Image/Foo.png'
import C1 from './Image/C1.png'
import C2 from './Image/C2.png'
import C3 from './Image/C3.png'
import C4 from './Image/C4.png'
import C5 from './Image/C5.png'
import C6 from './Image/C6.png'
import C7 from './Image/C7.png'
import C8 from './Image/C8.png'
import C9 from './Image/C9.png'

import C10 from './Image/C10.png'
import C11 from './Image/C11.png'
import C12 from './Image/C12.png'
import C13 from './Image/C13.png'
import C14 from './Image/C14.png'
import C15 from './Image/C15.png'
import C16 from './Image/C16.png'

import 'typeface-inter';
// import { green, blue, grey, red,yellow,common} from "@mui/material/colors";

import jwt_decode from "jwt-decode";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {useMediaQuery} from '@mui/material';
import { Api_url } from './helper';
import { styled } from '@mui/system';
const CustomButton = styled(Button)`
  &:hover {
    background-color: #007F85; /* or specify the desired background color */
  }
`;

const Selected = () => {

	const [userData, setUserData] = useState(null);
	const navigate = useNavigate();
	const location = useLocation();
	const mobile = useMediaQuery('(max-width:600px)');
	const fromReader = location.state && location.state.fromReaderup;
	
	useEffect(() => {// sbse phle useeffect hi call hoyega
        const token = localStorage.getItem("token");
        if (token) {
          try {
			  const decoded = jwt_decode(token);
            // console.log(decoded);
            setUserData(decoded);
          } catch (error) {
            console.error(error);
          }
        } else {
          navigate('/landing');
        }
    
        // Fetch user data from the backend API
        fetchUserData();
      }, []);
      const fetchUserData = async () => {
        try {
          const token = localStorage.getItem("token");
          if (!token) {
            setError('Token not found.');
            return;
          }
          const decoded = jwt_decode(token);
			const userId = decoded._id;
			// console.log(userId);
      
          const response = await fetch(`${Api_url}/sign/user/${userId}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
          if (response.ok) {
            const data = await response.json();
            // console.log(data)
          setUserData(data);
        } else {
          // Handle error response
          const errorData = await response.json();
          setError(errorData.error);
        }
      } catch (error) {
        console.error(error);
        setError('Failed to fetch user data.');
      }
    };
	

	const [error, setError] = useState('');
	const [values, setValues] = useState([]);
	const [buttonDisabled, setButtonDisabled] = useState(true);
  // Function to handle adding/removing values from the array
  const handleValueToggle = (value) => {
    setValues((prevValues) => {
      if (prevValues.includes(value)) {
        return prevValues.filter((item) => item !== value);
      } else {
        return [...prevValues, value];
      }
	});
	//   console.log(values)
	};
	useEffect(() => {
		if (values.length === 3) {
		  setButtonDisabled(false);
		} else {
		  setButtonDisabled(true);
		}
	  }, [values]);
const handleSubmit = async (e) => {

		e.preventDefault();
		const userId = userData._id;
		const Email = userData.Email;
		const Name=userData.Name
		navigate('/done')
		try {
			// Make the POST request to the backend endpoint
			const response = await axios.post(`${Api_url}/Otp/api/selected-values`, {
			  userId,Email,Name,
			  values: values,
			});
	  
			// Handle the response if needed
			// console.log(response.data);
		  } catch (error) {
			// Handle errors if any
			console.error(error);
		  }
		
    }
  return (
    <div>
     <Grid container lg={12} justifyContent="center" alignItems="center" sx={{backgroundImage:`url(${W1})`, minHeight:"100vh"}}>
<Grid item lg={12} sx={{justifyContent:'center',display:'flex',alignItems:'center'}}>
    <Paper sx={{ width: mobile?"85%":'776px', height: mobile?"":'688px',borderRadius:'26px',justifyContent:'center',display:'flex',alignItems:'center',marginTop:mobile?'54px':'31px'}}>
      <Grid container lg={12} justifyContent="center" alignItems="center">
        
      <Grid item lg={12} xs={12} sx={{textAlign:'center',marginTop:mobile?'16px':''}}>
	  <Image
      Duration={0}
      src={Foo}
      style={{
       
        width: '133px',
        height: '58px',
        transitionDuration: '0',
        animation: '0',
      }}
    />
      </Grid>
      <Grid item lg={12} xs={12} sx={{textAlign:'center',marginTop:'20px'}}>
	  {location.state && location.state.fromReaderup ? (
          <Typography sx={{ fontSize: '20px', fontFamily: 'Inter', fontWeight: '600', color: '#1E1E1E' }}>
									 
		  {userData ? ` Hi Reader ${userData.Name}` : 'Hi Reader'}
          </Typography>
        ) : (
          <Typography sx={{ fontSize: '20px', fontFamily: 'Inter', fontWeight: '600', color: '#1E1E1E' }}>
            {userData ? `Hi Author ${userData.Name}` : 'Hi Author'}
          </Typography>
							  )}
						  </Grid>
      <Grid item lg={12}xs={12} sx={{textAlign:'center',marginTop:'10px'}}>
        <Typography sx={{fontSize:'16px',fontFamily:'Inter',fontWeight:'500',color:'#1E1E1E'}}>What do you like to read?<br/>
</Typography>
      </Grid>
      <Grid container lg={12} xs={12}>

     <Grid container lg={11} xs={12} sx={{margin:'auto'}}>
     <Grid container lg={4} xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
     <Button sx={{ background:values.includes('Adventorous') ?'#BED6C5' :'#FAF7F7',width: mobile?"119px":'204px' ,height:mobile?"50px":'70px',marginTop:'26px',borderRadius:'10px','&:hover': {
         backgroundColor: '#BED6C5'
									  },
									  }}
									  onClick={() => handleValueToggle('Adventorous')}
									  >
  <Grid container lg={10} xs={12} alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
    <Grid container lg={5} xs={5}>
      <img src={C1} alt="My Image" style={{width:mobile?"26px":'50px',height:mobile?"26px":'50px'}}  />
    </Grid>
    <Grid container lg={5} xs={5}>
      <Typography sx={{ fontFamily: 'Inter', fontSize: mobile?"10px":'16px', fontWeight: '600', color: '#1E1E1E', textTransform: 'none' }}>
        Adventure
      </Typography>
    </Grid>
  </Grid>
</Button>






<Button sx={{ background:values.includes('Horror') ?'#BED6C5' :'#FAF7F7',width:mobile?"119px":'204px' ,height:mobile?"50px":'70px',marginTop:'26px',borderRadius:'10px','&:hover': {
          backgroundColor: '#BED6C5', 
									  },
									  }}
									  onClick={() => handleValueToggle('Horror')}>
  <Grid container lg={10}xs={12} alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
    <Grid container lg={5} xs={5}>
      <img src={C2} alt="My Image" style={{width:mobile?"26px":'50px',height:mobile?"26px":'50px'}}/>
    </Grid>
    <Grid container lg={5} xs={5}>
      <Typography sx={{ fontFamily: 'Inter', fontSize:mobile?"10px": '16px', fontWeight: '600', color: '#1E1E1E', textTransform: 'none' }}>
       Horror
      </Typography>
    </Grid>
  </Grid>
</Button>
      
<Button sx={{background:values.includes('Fantasy') ?'#BED6C5' :'#FAF7F7',width:mobile?"119px":'204px' ,height:mobile?"50px":'70px',marginTop:'26px',borderRadius:'10px','&:hover': {
          backgroundColor: '#BED6C5', 
									  },
									  }}
									  onClick={() => handleValueToggle('Fantasy')}>
  <Grid container lg={10} xs={12} alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
    <Grid container lg={5} xs={5}>
      <img src={C3} alt="My Image" style={{width:mobile?"26px":'50px',height:mobile?"26px":'50px'}} />
    </Grid>
    <Grid container lg={5} xs={5}>
      <Typography sx={{ fontFamily: 'Inter', fontSize: mobile?"10px":'16px', fontWeight: '600', color: '#1E1E1E', textTransform: 'none' }}>
       Fantasy
      </Typography>
    </Grid>
  </Grid>
</Button>
<Button sx={{ backgroundColor:values.includes('Fan Fiction') ?'#BED6C5' :'#FAF7F7',width:mobile?"119px":'204px' ,height:mobile?"50px":'70px',marginTop:'26px',borderRadius:'10px','&:hover': {
          backgroundColor: '#BED6C5', 
									  },
									  }}
									  onClick={() => handleValueToggle('Fan Fiction')}>
<Grid container lg={10} alignItems="center" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <Grid container lg={5} xs={5}>
    <img src={C4} alt="My Image" style={{width:mobile?"26px":'50px',height:mobile?"26px":'50px'}} />
  </Grid>
  <Grid container lg={5} xs={5} sx={{ whiteSpace: 'nowrap' }}>
    <Typography sx={{ fontFamily: 'Inter', fontSize: mobile?"10px":'16px', fontWeight: '600', color: '#1E1E1E', textTransform: 'none' }}>
      Fan Fiction
    </Typography>
  </Grid>
</Grid>

</Button>
     
      

     
      </Grid>
      <Grid container lg={4} xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      <Button sx={{  backgroundColor:values.includes('Teen Fiction') ?'#BED6C5' :'#FAF7F7',width:mobile?"119px":'204px' ,height:mobile?"50px":'70px',marginTop:'26px',borderRadius:'10px','&:hover': {
          backgroundColor: '#BED6C5', 
        },}}  onClick={() => handleValueToggle('Teen Fiction')}>
<Grid container lg={10} alignItems="center" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <Grid container lg={5} xs={5}>
    <img src={C5} alt="My Image" style={{width:mobile?"26px":'50px',height:mobile?"26px":'50px'}} />
  </Grid>
  <Grid container lg={5} xs={5} sx={{ whiteSpace: 'nowrap' }}>
    <Typography sx={{ fontFamily: 'Inter', fontSize: mobile?"10px":'16px', fontWeight: '600', color: '#1E1E1E', textTransform: 'none' }}>
       Fiction
    </Typography>
  </Grid>
</Grid>

</Button>
<Button sx={{  backgroundColor:values.includes('Romance') ?'#BED6C5' :'#FAF7F7',width:mobile?"119px":'204px' ,height:mobile?"50px":'70px',marginTop:'26px',borderRadius:'10px','&:hover': {
          backgroundColor: '#BED6C5', 
        },}} onClick={() => handleValueToggle('Romance')}>
<Grid container lg={10} alignItems="center" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <Grid container lg={5} xs={5}>
    <img src={C6} alt="My Image" style={{width:mobile?"26px":'50px',height:mobile?"26px":'50px'}} />
  </Grid>
  <Grid container lg={5} xs={5} sx={{ whiteSpace: 'nowrap' }}>
    <Typography sx={{ fontFamily: 'Inter', fontSize: mobile?"10px":'16px', fontWeight: '600', color: '#1E1E1E', textTransform: 'none' }}>
      Romance
    </Typography>
  </Grid>
</Grid>

</Button>
      
<Button sx={{backgroundColor:values.includes('Mystery') ?'#BED6C5' :'#FAF7F7',width:mobile?"119px":'204px' ,height:mobile?"50px":'70px',marginTop:'26px',borderRadius:'10px','&:hover': {
          backgroundColor: '#BED6C5', 
        },}}  onClick={() => handleValueToggle('Mystery')}>
  <Grid container lg={10} alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
    <Grid container lg={5} xs={5}>
      <img src={C7} alt="My Image" style={{width:mobile?"26px":'50px',height:mobile?"26px":'50px'}} />
    </Grid>
    <Grid container lg={5} xs={5}>
      <Typography sx={{ fontFamily: 'Inter', fontSize: mobile?"10px":'16px', fontWeight: '600', color: '#1E1E1E', textTransform: 'none' }}>
      Mystery
      </Typography>
    </Grid>
  </Grid>
</Button>
<Button sx={{ backgroundColor:values.includes('Sci-Fi') ?'#BED6C5' :'#FAF7F7',width:mobile?"119px":'204px' ,height:mobile?"50px":'70px',marginTop:'26px',borderRadius:'10px','&:hover': {
          backgroundColor: '#BED6C5', 
        },}} onClick={() => handleValueToggle('Sci-Fi')}>
  <Grid container lg={10} alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
    <Grid container lg={5} xs={5}>
      <img src={C8} alt="My Image" style={{width:mobile?"26px":'50px',height:mobile?"26px":'50px'}} />
    </Grid>
    <Grid container lg={5} xs={5}>
      <Typography sx={{ fontFamily: 'Inter', fontSize:mobile?"10px": '16px', fontWeight: '600', color: '#1E1E1E', textTransform: 'none' }}>
      Sci-Fi
      </Typography>
    </Grid>
  </Grid>
</Button>
     
      

     
      </Grid>
								  {mobile ? <>
									<Grid container lg={4} xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
									<Button sx={{
										  backgroundColor: values.includes('Non-Fiction') ? '#BED6C5' : '#FAF7F7', width: mobile ? "119px" : '204px', height: mobile ? "50px" : '70px', marginTop: '26px', borderRadius: '10px', '&:hover': {
          backgroundColor: '#BED6C5', 
        },}} onClick={() => handleValueToggle('Non-Fiction')}>
  <Grid container lg={10} alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
    <Grid container lg={5} xs={5}>
      <img src={C9} alt="My Image" style={{width:mobile?"26px":'50px',height:mobile?"26px":'50px'}} />
    </Grid>
    <Grid container lg={5} xs={5} sx={{ whiteSpace: 'nowrap' }}>
      <Typography sx={{ fontFamily: 'Inter', fontSize:mobile?"10px": '16px', fontWeight: '600', color: '#1E1E1E', textTransform: 'none' }}>
    Non-Fiction
      </Typography>
    </Grid>
  </Grid>
										  </Button>






										  <Button sx={{ backgroundColor: values.includes('Comedy') ?'#BED6C5' :'#FAF7F7',width:mobile?"119px":'204px' ,height:mobile?"50px":'70px',marginTop:'26px',borderRadius:'10px','&:hover': {
          backgroundColor: '#BED6C5',
        },}} onClick={() => handleValueToggle('Comedy')}>
  <Grid container lg={10} alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
    <Grid container lg={5} xs={5}>
      <img src={C10} alt="My Image" style={{width:mobile?"26px":'50px',height:mobile?"26px":'50px'}} />
    </Grid>
    <Grid container lg={5} xs={5}>
      <Typography sx={{ fontFamily: 'Inter', fontSize: mobile?"10px":'16px', fontWeight: '600', color: '#1E1E1E', textTransform: 'none' }}>
     Comedy
      </Typography>
    </Grid>
  </Grid>
										  </Button>
      

     
      

     
									  </Grid>
									  <Grid container lg={4} xs={6} sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
									  <Button sx={{ backgroundColor:values.includes('Poetry') ?'#BED6C5' :'#FAF7F7',width:mobile?"119px":'204px' ,height:mobile?"50px":'70px',marginTop:'26px',borderRadius:'10px','&:hover': {
          backgroundColor: '#BED6C5', 
        },}} onClick={() => handleValueToggle('Poetry')}>
  <Grid container lg={10} alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
    <Grid container lg={5} xs={5}>
      <img src={C11} alt="My Image" style={{width:mobile?"26px":'50px',height:mobile?"26px":'50px'}} />
    </Grid>
    <Grid container lg={5} xs={5}>
      <Typography sx={{ fontFamily: 'Inter', fontSize: mobile?"10px":'16px', fontWeight: '600', color: '#1E1E1E', textTransform: 'none' }}>
      Poetry
      </Typography>
    </Grid>
  </Grid>
</Button>






<Button sx={{ backgroundColor: values.includes('Thriller') ?'#BED6C5' :'#FAF7F7',width:mobile?"119px":'204px' ,height:mobile?"50px":'70px',marginTop:'26px',borderRadius:'10px','&:hover': {
          backgroundColor: '#BED6C5', // Change to desired green color
        },}} onClick={() => handleValueToggle('Thriller')}>
  <Grid container lg={10} alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
    <Grid container lg={5} xs={5}>
      <img src={C12} alt="My Image" style={{width:mobile?"26px":'50px',height:mobile?"26px":'50px'}} />
    </Grid>
    <Grid container lg={5} xs={5}>
      <Typography sx={{ fontFamily: 'Inter', fontSize: mobile?"10px":'16px', fontWeight: '600', color: '#1E1E1E', textTransform: 'none' }}>
      Thriller
      </Typography>
    </Grid>
  </Grid>
										  </Button>
      

     
      

     
      </Grid>
									  
									  <Grid container lg={4} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									  
									  <Grid container lg={4} xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  	  
									  <Grid item xs={6}>

										  </Grid>
									  
									  
									 
     
      
</Grid>
     
									  </Grid>
									  </>: <Grid container lg={4} xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Button sx={{ backgroundColor:values.includes('Non-Fiction') ?'#BED6C5' :'#FAF7F7',width:mobile?"119px":'204px' ,height:mobile?"50px":'70px',marginTop:'26px',borderRadius:'10px','&:hover': {
          backgroundColor: '#BED6C5', 
        },}} onClick={() => handleValueToggle('Non-Fiction')}>
  <Grid container lg={10} alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
    <Grid container lg={5} xs={5}>
      <img src={C13} alt="My Image" style={{width:mobile?"26px":'50px',height:mobile?"26px":'50px'}} />
    </Grid>
    <Grid container lg={5} xs={5} sx={{ whiteSpace: 'nowrap' }}>
      <Typography sx={{ fontFamily: 'Inter', fontSize:mobile?"10px": '16px', fontWeight: '600', color: '#1E1E1E', textTransform: 'none' }}>
    Non-Fiction
      </Typography>
    </Grid>
  </Grid>
</Button>
<Button sx={{ backgroundColor:values.includes('Poetry') ?'#BED6C5' :'#FAF7F7',width:mobile?"119px":'204px' ,height:mobile?"50px":'70px',marginTop:'26px',borderRadius:'10px','&:hover': {
          backgroundColor: '#BED6C5', 
        },}} onClick={() => handleValueToggle('Poetry')}>
  <Grid container lg={10} alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
    <Grid container lg={5} xs={5}>
      <img src={C14} alt="My Image" style={{width:mobile?"26px":'50px',height:mobile?"26px":'50px'}} />
    </Grid>
    <Grid container lg={5} xs={5}>
      <Typography sx={{ fontFamily: 'Inter', fontSize: mobile?"10px":'16px', fontWeight: '600', color: '#1E1E1E', textTransform: 'none' }}>
      Poetry
      </Typography>
    </Grid>
  </Grid>
</Button>
      
<Button sx={{ backgroundColor: values.includes('Comedy') ?'#BED6C5' :'#FAF7F7',width:mobile?"119px":'204px' ,height:mobile?"50px":'70px',marginTop:'26px',borderRadius:'10px','&:hover': {
          backgroundColor: '#BED6C5',
        },}} onClick={() => handleValueToggle('Comedy')}>
  <Grid container lg={10} alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
    <Grid container lg={5} xs={5}>
      <img src={C15} alt="My Image" style={{width:mobile?"26px":'50px',height:mobile?"26px":'50px'}} />
    </Grid>
    <Grid container lg={5} xs={5}>
      <Typography sx={{ fontFamily: 'Inter', fontSize: mobile?"10px":'16px', fontWeight: '600', color: '#1E1E1E', textTransform: 'none' }}>
     Comedy
      </Typography>
    </Grid>
  </Grid>
</Button>
<Button sx={{ backgroundColor: values.includes('Thriller') ?'#BED6C5' :'#FAF7F7',width:mobile?"119px":'204px' ,height:mobile?"50px":'70px',marginTop:'26px',borderRadius:'10px','&:hover': {
          backgroundColor: '#BED6C5', // Change to desired green color
        },}} onClick={() => handleValueToggle('Thriller')}>
  <Grid container lg={10} alignItems="center" sx={{ display: 'flex', justifyContent: 'center' }}>
    <Grid container lg={5} xs={5}>
      <img src={C16} alt="My Image" style={{width:mobile?"26px":'50px',height:mobile?"26px":'50px'}} />
    </Grid>
    <Grid container lg={5} xs={5}>
      <Typography sx={{ fontFamily: 'Inter', fontSize: mobile?"10px":'16px', fontWeight: '600', color: '#1E1E1E', textTransform: 'none' }}>
      Thriller
      </Typography>
    </Grid>
  </Grid>
</Button>
     
      

     
      </Grid>}
      </Grid>
      </Grid>
      <Grid item lg={12}>
        <Typography sx={{fontSize:mobile?'10px':'13px',fonWeight:500,color:'#1e1e1e',lineHeight:'24px',marginTop:mobile?'10px':'20px',textAlign:'center'}}>* Choose Only Three Genre</Typography>
      </Grid>
      <Grid item lg={9} sx={{marginTop:mobile?"20px":'40px',textAlign:'center',marginBottom:mobile?"20px":'0px'}}>

<CustomButton onClick={handleSubmit}
  type="submit"
  variant="contained"
  sx={{
    mt: 0,
    mb: 0,
    background: '#007F85',
    borderRadius: '8px',
    textTransform: 'none',
    width: mobile?"36.5vh":'177px',
    height: mobile?"38px":'48px',
    fontSize: mobile?"14px":'16px',
    fontFamily: 'Inter',
    fontWeight: '500'
								  }}
								  disabled={buttonDisabled}
>
 Finish
</CustomButton>

</Grid>

      </Grid>
    </Paper>
    </Grid>
</Grid>
    </div>
  )
}

export default Selected