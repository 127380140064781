import  React,{useState,useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Header from './Header';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Close from '@mui/icons-material/Close';
import Logo from './Image/logo.png'
import axios from 'axios';
import Footer from './Footer';
import { Grid,Typography,Button,Divider, DialogTitle } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import DialogContentText from "@mui/material/DialogContentText";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoneIcon from '@mui/icons-material/Done';
import Mobileheader from './Mobileheader';
import {useMediaQuery} from '@mui/material';
import useRazorpay from "react-razorpay";
import { Api_url } from './helper';
import jwt_decode from 'jwt-decode';
import 'typeface-inter';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const CustomButton = styled(Button)`
  &:hover {
    background-color: #086266; /* or specify the desired background color */
  }
`;

 function Pro() {
    const mobile=useMediaQuery('(max-width:600px)')
    const [Razorpay] = useRazorpay();
	const [openForm, setOpenForm] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState('');
	const handleGetNowClick = () => {
	  setOpenForm(true);
	 };
	 const [loading, setLoading] = useState(false);
	 const [openForm2, setOpenForm2] = useState(false);
	 const handleGetNowClick2 = () => {
		setOpenForm2(true);
	 };
	 const [openForm3, setOpenForm3] = useState(false);
	 const handleGetNowClick3 = () => {
		setOpenForm3(true);
	   };
	 const[user,setUser]=useState({})
	 useEffect(() => {
		// Fetch user data on component mount
		fetchUserData();
	  }, []);
	
	  const fetchUserData = async () => {
		try {
			const token = localStorage.getItem("token");
			if (!token) {
			  
			  return;
			}
		
			const decoded = jwt_decode(token);
			const userId = decoded._id;
		
			const response = await fetch(`${Api_url}/sign/user/${userId}`, {
			  method: 'GET',
			  headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`,
			  },
			});
			if (response.ok) {
			  const data = await response.json();
			//   console.log(data)
			setUser(data);
		  } else {
			// Handle error response
			const errorData = await response.json();	
		  }
		} catch (error) {
		  console.error(error);
		
		}
	 };
	 const handleClose = () => {
		 setOpenForm2(false);
		 setLoading(false);
		 setPhoneNumber('');
		 
	 }
	 const handleClose2 = () => {
		setOpenForm(false);
		 setLoading(false);
		 setPhoneNumber('');
	 }
	 const handleClose3 = () => {
		setOpenForm3(false);
		 setLoading(false);
		 setPhoneNumber('');
	}
const handlePayment = async (value) => {
    const response = await axios.post(`${Api_url}/otp/create-order/${value}`);
    // console.log(response,'ssfsf')
    const { data } = response;
// console.log(data,'sss')
  const options = {
    key: "rzp_test_Jtpvppi2NrnCCK", 
    amount: data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: "INR",
    name: "Slushie",
    description: "Test Transaction",
    image: Logo,
    order_id: data.id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
    handler: function (response) {
      alert(response.razorpay_payment_id);
      alert(response.razorpay_order_id);
      alert(response.razorpay_signature);
    },
    prefill: {
      name: "Piyush Garg",
      email: "youremail@example.com",
      contact: "9999999999",
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };

  const rzp1 = new Razorpay(options);

  rzp1.on("payment.failed", function (response) {
    alert(response.error.code);
    alert(response.error.description);
    alert(response.error.source);
    alert(response.error.step);
    alert(response.error.reason);
    alert(response.error.metadata.order_id);
    alert(response.error.metadata.payment_id);
  });

  rzp1.open();
	 };
	 const handleEmail = async (value) => {
		const phoneNumberValue = phoneNumber; // Get the phone number from your state
		setLoading(true);
		// Create a data object to send to the server
		const data = {
		  name: user.Name, // Replace with your name value
		  email: user.Email, // Replace with your email value
			phone: phoneNumberValue,
			value:value// Use the phone number value
		 };
		//  console.log(data, 'handleEMail');
		 try {
			// Set loading to true before making the API call
		
			const response = await axios.post(`${Api_url}/otp/sendEmail`, { data });
			setLoading(true); 
			// console.log('Email sent:', response.data);
		
			// Handle the API response or perform any other actions here
		
		  } catch (error) {
			console.error('Error sending email:', error);
			setLoading(true); 
			// Handle the error here
		
		  } 
	 }

   const[basic,setbasic]=useState(false)
   const[basic1,setbasic1]=useState(false)
   const[basic2,setbasic2]=useState(false)
   const handleClick=()=>{
    setbasic(!basic)
   }
   const handleClick1=()=>{
    setbasic1(!basic1)
   }
   const handleClick2=()=>{
    setbasic2(!basic2)
   }
  return (
   
    <>
        {mobile?<Mobileheader/>: <Header/>}
         <Grid container lg={12} xs={12}>
<Grid container lg={9} xs={12} sx={{margin:'auto'}}>
<Grid item lg={12 } xs={9.5} sx={{marginTop:'80px',marginBottom:'62px',textAlign:'center',margin:mobile?'auto':'0px'}} >
    <Typography sx={{fontFamily:'Inter',fontWeight:mobile?'700':'800',fontSize:mobile?'26px':'48px',textAlign:mobile?'initial':'center',marginTop:mobile?'26px':'80px',marginBottom:mobile?'36px':'62px'}}>Pricing Plans For <span style={{fontFamily:'Inter',fontWeight:mobile?'700':'800',fontSize:mobile?'26px':'48px',color:'#007f85'}}>Slushie Pro</span> </Typography>
</Grid>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        style={{ alignItems:mobile?'center':'' }}
      >
        <Item sx={{borderRadius:'4px',border:'1px solid #DBDBDB',height:basic?mobile?'600px':'820px':mobile?'390px':'552px',width:mobile?'80%':'100%'}}>
            <Grid container lg={12} xs={12}>
                <Grid container lg={10} xs={12} sx={{margin:'auto',display:mobile?'flex':'0',justifyContent:mobile?'center':'0'}}>
                   <Grid item lg={12} xs={12}>
                        <Typography sx={{color:'#000',fontWeight:'700',fontSize:mobile?'16px':'24px',fontFamily:'Inter',marginTop:'56px'}}>Basic</Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Typography sx={{fontFamily:'Inter',fontSize:mobile?'32px':'64px',fontWeight:'700',color:'#1e1e1e',marginTop:mobile?'36px':'0px'}}>₹20K</Typography>
                    </Grid>
                    {/* <Grid item lg={12}>
                        <Typography sx={{fontFamily:'Inter',fontSize:'14px',fontWeight:'500',color:'#747474',}}>per user</Typography>
                    </Grid> */}
                    {mobile?'':<Grid item lg={12} >
                    <Divider sx={{ margin: 'auto', width:'18%',marginTop:'22px', }} />
                    </Grid>}
                    <Grid item lg={12} xs={12}>
                        <Typography sx={{color:'#000',fontWeight:'600',fontSize:mobile?'14px':'18px',fontFamily:'Inter',marginTop:mobile?'26px':'22px',display:'flex',justifyContent:'center',alignItems:'center',marginRight:mobile?'55px':'0px'}}><DoneIcon style={{color:'#007f85',width:mobile?'18px':'28px',height:'28px',strokeWidth:'3px',marginRight:'16px'}}/>Pitching Service</Typography>
								  </Grid>
								  <Grid item lg={12} sx={{width:'300px'}}>
                    {basic?<>
                     
                    <Grid item lg={8.5} xs={8} sx={{margin:'auto'}} >
                        <Typography sx={{fontSize:mobile?'12px':'14px',marginTop:'26px',fontWeight:'500',color:'#000',textAlign:'initial'}} > <CircleIcon style={{width:mobile?'6px':'8px',height:mobile?'6px':'8px',marginRight:'8px'}}/>We’ll connect you with a literary agent who specialises in your book’s genre  </Typography>
                    </Grid>
                    
                  
                    <Grid item lg={8.5}  xs={8} sx={{margin:'auto'}} >
                        <Typography sx={{fontSize:mobile?'12px':'14px',fontWeight:'500',color:'#000',textAlign:'initial',marginTop:'16px',}}> <CircleIcon style={{width:mobile?'6px':'8px',height:mobile?'6px':'8px',marginRight:'8px'}}/>The agent will serve as your advocate by pitching the book on your behalf to the publishers.</Typography>
                    </Grid>
                    <Grid item lg={8.5} xs={8} sx={{margin:'auto'}} >
                        <Typography sx={{fontSize:mobile?'12px':'14px',fontWeight:'500',color:'#000',textAlign:'initial',marginTop:'16px',}}> <CircleIcon style={{width:mobile?'6px':'8px',height:mobile?'6px':'8px',marginRight:'8px'}}/>This service is invaluable for authors looking to take the next step in their writing careers.</Typography>
                    </Grid>
                   
                    <Grid item lg={12}>
                        <Button onClick={handleClick} sx={{fontWeight:'600',fontSize:mobile?'10px':'14px',fontFamily:'Inter',color:'#007f85',textTransform:'none',marginTop:'26px'}}>See less</Button>
                    </Grid></>:
                    <Grid item lg={12} xs={12}>
                        <Button onClick={handleClick} sx={{fontWeight:'600',fontSize:mobile?'10px':'14px',fontFamily:'Inter',color:'#007f85',textTransform:'none',marginTop:mobile?'20px':'26px'}}>See more</Button>
										  </Grid>}
										  </Grid>
                    <Grid item lg={12} xs={12}>
                        <CustomButton onClick={()=>handleGetNowClick2()}  variant='contained' sx={{backgroundColor:'#007f85',color:'#fff',textTransform:'none',width:'220px',padding:'12px 20px',marginTop:mobile?'36px':'100px',fontSize:mobile?'14px':'16px',fontWeight:'500',marginBottom:mobile?'36px':'56px',borderRadius:'8px'}}>Get Now</CustomButton>
                    </Grid>
                </Grid>
						  </Grid>
						  <Dialog open={openForm2} onClose={() => setOpenForm2(false)}>
							  <DialogTitle sx={{ display: 'flex', justifyContent: 'end', height: '5px' }} ><Close onClick={() => handleClose()} /></DialogTitle>
  <DialogContent>
								  {loading ? <>
									  <DialogContentText id="alert-dialog-description">
										  <Grid container lg={12} sx={{justifyContent:'center'}}>
											  <Grid item lg={12} sx={{ alignItems:'center',display:'flex',justifyContent:'center'}}>
											  <CheckCircleOutlineIcon style={{width:"47px",height:'47px',color:"#007F85"}} /> 
											  </Grid>
											  <Grid item lg={12}>
											  <Typography sx={{color: '#000',

textAlign: 'center',
fontFamily: 'Inter',
											  fontSize:'20px',
fontStyle: 'normal',
fontWeight: 500,
											  lineHeight: 'normal'
												  }}>Your request has been submitted.</Typography>	
												  <Typography sx={{color: '#000',

textAlign: 'center',
fontFamily: 'Inter',
											  fontSize:'16px',
fontStyle: 'normal',
fontWeight: 400,
											  lineHeight: '22.4px'
										  }}>Our team will contact you soon for further processing.</Typography>
												  </Grid>
										  </Grid>
           
            </DialogContentText></> :
									  <>
										  <Typography sx={{color: '#000',marginTop:'26px',

textAlign: 'center',
fontFamily: 'Inter',
											  fontSize:'20px',
fontStyle: 'normal',
fontWeight: 500,
											  lineHeight: 'normal'
										  }}>To continue, please enter your phone number.</Typography>
										  <Grid container lg={12} sx={{marginTop:'26px'}}>
											  <Grid item lg={8}>
											  <TextField placeholder="Phone Number" variant="outlined" size="small" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} style={{width:mobile?"273px":"295px",height:"39px",borderRadius: '8px',
border: '1px solid #007F85'}} />
   			  
											  </Grid>
											  <Grid item lg={4}>
	 <CustomButton variant="contained" color="primary" onClick={()=>handleEmail(20000)}  sx={{backgroundColor:'#007f85',height:"39px",color:'#fff',textTransform:'none',width:mobile?"273px":'180px',padding:'12px 20px',marginTop:mobile?'36px':'0px',fontSize:mobile?'14px':'16px',fontWeight:'500',marginBottom:'0px',borderRadius:'8px'}}>
													  
													  {loading ? <CircularProgress style={{ color: "#FFFFFF" }} />: 'Continue'} 
												  </CustomButton>	  
												  </Grid>
										  </Grid>
										 
									
									  </>}
									  </DialogContent>
</Dialog>
        </Item>
        <Item  sx={{backgroundColor:'#2C3140',borderRadius:'4px',border:'1px solid #DBDBDB',height:basic1?mobile?'763px':'1020px':mobile?'430px':'552px',width:mobile?'80%':'100%'}} style={{ marginTop:mobile?'36px': '0px' }}>
        <Grid container lg={12} xs={12}>
                <Grid container lg={10} sx={{margin:'auto',display:mobile?'flex':'0',justifyContent:mobile?'center':'0'}}>
                    <Grid item lg={12} xs={12}>
                        <Typography sx={{color:'#fff',fontWeight:'700',fontSize:mobile?'16px':'24px',fontFamily:'Inter',marginTop:'56px'}}>Essential</Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Typography sx={{fontFamily:'Inter',fontSize:mobile?'32px':'64px',fontWeight:'700',color:'#fff',marginTop:mobile?'36px':'0px'}}>₹40K</Typography>
                    </Grid>
                    {/* <Grid item lg={12}>
                        <Typography sx={{fontFamily:'Inter',fontSize:'14px',fontWeight:'500',color:'#747474',}}>per user</Typography>
                    </Grid> */}
                   {mobile?'': <Grid item lg={12}>
                    <Divider sx={{ margin: 'auto', width:'18%', marginTop:'22px',color:'#fff'}}
                      style={{ backgroundColor: '#fff' }} />
                    </Grid>}
                    <Grid item lg={12} xs={12}>
                        <Typography sx={{color:'#fff',fontWeight:'600',fontSize:mobile?'14px':'18px',fontFamily:'Inter',marginTop:mobile?'26px':'22px',display:'flex',justifyContent:'center',alignItems:'center',marginRight:mobile?'55px':'0px'}}><DoneIcon style={{color:'#007f85',width:mobile?'18px':'28px',height:'28px',strokeWidth:'3px',marginRight:'16px'}}/>Pitching Service</Typography>
								  </Grid>
                                  <Grid item lg={12} xs={12}>
                        <Typography sx={{color:'#fff',fontWeight:'600',fontSize:mobile?'14px':'18px',fontFamily:'Inter',marginTop:mobile?'10px':'22px',display:'flex',justifyContent:'center',alignItems:'center',marginRight:mobile?'55px':'0px'}}><DoneIcon style={{color:'#007f85',width:mobile?'18px':'28px',height:'28px',strokeWidth:'3px',marginRight:'16px'}}/>Editorial Service</Typography>
								  </Grid>
<Grid item lg={12} sx={{width:'300px'}}>
                    {basic1?<>
                    <Grid item lg={9.8} xs={9} sx={{margin:'auto'}}>
                        <Typography sx={{fontSize:mobile?'12px':'14px',fontWeight:'500',color:'#fff',textAlign:'initial',marginTop:'26px',}}> <CircleIcon style={{width:mobile?'6px':'8px',height:'8px',marginRight:'8px'}}/>In addition to connecting you with a literary agent, you'll receive professional editorial guidance to enhance the quality of your manuscript.</Typography>
                    </Grid>
                    <Grid item lg={9.8} xs={9} sx={{margin:'auto'}} >
                        <Typography sx={{fontSize:mobile?'12px':'14px',fontWeight:'500',color:'#fff',textAlign:'initial',marginTop:'16px',}}> <CircleIcon style={{width:mobile?'6px':'8px',height:'8px',marginRight:'8px'}}/>Our professional proofreaders will meticulously review your manuscript to correct grammatical errors, typos, and ensure consistent formatting. They will also pay special attention to your synopsis to make it concise, engaging, and reflective of your story.</Typography>
                    </Grid>
                    <Grid item lg={9.8} xs={9} sx={{margin:'auto'}} >
                        <Typography sx={{fontSize:mobile?'12px':'14px',fontWeight:'500',color:'#fff',textAlign:'initial',marginTop:'16px',}}> <CircleIcon style={{width:mobile?'6px':'8px',height:'8px',marginRight:'8px'}}/>This package is designed to present your work in the best possible light to publishers, increasing the chances of acceptance and a successful publishing journey.</Typography>
                    </Grid>
                    <Grid item lg={12}>
                        <Button onClick={handleClick1} sx={{fontWeight:'600',fontSize:mobile?'10px':'14px',fontFamily:'Inter',color:'#007f85',textTransform:'none',marginTop:'26px',color:'#fff',}}>See less</Button>
                    </Grid></>:
                    <Grid item lg={12}>
                        <Button onClick={handleClick1} sx={{fontWeight:'600',fontSize:mobile?'10px':'14px',fontFamily:'Inter',color:'#fff',textTransform:'none',marginTop:mobile?'20px':'26px'}}>See more</Button>
                    </Grid>}
                    </Grid>
                    <Grid item lg={12}>
                        <CustomButton variant='contained' onClick={()=>handleGetNowClick()} sx={{backgroundColor:'#007f85',color:'#fff',textTransform:'none',width:'220px',padding:'12px 20px',marginTop:mobile?'36px':'63px',fontSize:mobile?'14px':'16px',fontWeight:'500',marginBottom:'56px',borderRadius:'8px'}}>Get Now</CustomButton>
                    </Grid>
                </Grid>
						  </Grid>
						  <Dialog open={openForm} onClose={() => setOpenForm(false)}>
						  <DialogTitle sx={{ display: 'flex', justifyContent: 'end', height: '5px' }} ><Close onClick={() => handleClose2()} /></DialogTitle>
						  <DialogContent>
								  {loading ? <>
									  <DialogContentText id="alert-dialog-description">
										  <Grid container lg={12} sx={{justifyContent:'center'}}>
											  <Grid item lg={12} sx={{ alignItems:'center',display:'flex',justifyContent:'center'}}>
											  <CheckCircleOutlineIcon style={{width:"47px",height:'47px',color:"#007F85"}} /> 
											  </Grid>
											  <Grid item lg={12}>
											  <Typography sx={{color: '#000',

textAlign: 'center',
fontFamily: 'Inter',
											  fontSize:'20px',
fontStyle: 'normal',
fontWeight: 500,
											  lineHeight: 'normal'
												  }}>Your request has been submitted.</Typography>	
												  <Typography sx={{color: '#000',

textAlign: 'center',
fontFamily: 'Inter',
											  fontSize:'16px',
fontStyle: 'normal',
fontWeight: 400,
											  lineHeight: '22.4px'
										  }}>Our team will contact you soon for further processing.</Typography>
												  </Grid>
										  </Grid>
           
            </DialogContentText></> :
									  <>
										  <Typography sx={{color: '#000',marginTop:'26px',

textAlign: 'center',
fontFamily: 'Inter',
											  fontSize:'20px',
fontStyle: 'normal',
fontWeight: 500,
											  lineHeight: 'normal'
										  }}>To continue, please enter your phone number.</Typography>
										  <Grid container lg={12} sx={{marginTop:'26px'}}>
											  <Grid item lg={8}>
											  <TextField placeholder="Phone Number" variant="outlined" size="small" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} style={{width:mobile?"273px":"295px",height:"39px",borderRadius: '8px',
border: '1px solid #007F85'}} />
   			  
											  </Grid>
											  <Grid item lg={4}>
	 <CustomButton variant="contained" color="primary" onClick={()=>handleEmail(40000)}   sx={{backgroundColor:'#007f85',height:"39px",color:'#fff',textTransform:'none',width:mobile?"273px":'180px',padding:'12px 20px',marginTop:mobile?'36px':'0px',fontSize:mobile?'14px':'16px',fontWeight:'500',marginBottom:'0px',borderRadius:'8px'}}>
													  
													  {loading ? <CircularProgress style={{ color: "#FFFFFF" }} />: 'Continue'} 
												  </CustomButton>	  
												  </Grid>
										  </Grid>
										 
									
									  </>}
									  </DialogContent>
</Dialog>
        </Item>
					  <Item sx={{borderRadius:'4px',border:'1px solid #DBDBDB',height:basic2?mobile?'989px':'1199px':mobile?'450px':'552px',width:mobile?'80%':'100%'}} style={{ marginTop:mobile? '36px':'0px' }}>
						  <Grid container lg={12}>
                <Grid container lg={10} sx={{margin:'auto',display:mobile?'flex':'0',justifyContent:mobile?'center':'0'}}>
                <Grid item lg={12} xs={12}>
                        <Typography sx={{color:'#000',fontWeight:'700',fontSize:mobile?'16px':'24px',fontFamily:'Inter',marginTop:'56px'}}>Premium</Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <Typography sx={{fontFamily:'Inter',fontSize:mobile?'32px':'64px',fontWeight:'700',color:'#000',marginTop:mobile?'36px':'0px'}}>₹60K</Typography>
                    </Grid>
                    {/* <Grid item lg={12}>
                        <Typography sx={{fontFamily:'Inter',fontSize:'14px',fontWeight:'500',color:'#747474',}}>per user</Typography>
                    </Grid> */}
                  {mobile?'':  <Grid item lg={12}>
                    <Divider sx={{ margin: 'auto', width:'18%',marginTop:'22px', }} />
                    </Grid>}
                    <Grid item lg={12} xs={12}>
                        <Typography sx={{color:'#000',fontWeight:'600',fontSize:mobile?'14px':'18px',fontFamily:'Inter',marginTop:mobile?'26px':'22px',display:'flex',justifyContent:'center',alignItems:'center',marginRight:mobile?'55px':'0px'}}><DoneIcon style={{color:'#007f85',width:mobile?'18px':'28px',height:'28px',strokeWidth:'3px',marginRight:'16px'}}/>Pitching Service</Typography>
								  </Grid>
                                  <Grid item lg={12} xs={12}>
                        <Typography sx={{color:'#000',fontWeight:'600',fontSize:mobile?'14px':'18px',fontFamily:'Inter',marginTop:mobile?'10px':'22px',display:'flex',justifyContent:'center',alignItems:'center',marginRight:mobile?'45px':'0px'}}><DoneIcon style={{color:'#007f85',width:mobile?'18px':'28px',height:'28px',strokeWidth:'3px',marginRight:'16px'}}/>Editorial Services</Typography>
								  </Grid>
                                  <Grid item lg={12} xs={5}>
                        <Typography sx={{color:'#000',fontWeight:'600',fontSize:mobile?'14px':'18px',fontFamily:'Inter',marginTop:mobile?'10px':'22px',display:'flex',justifyContent:'center',alignItems:'center',marginRight:mobile?'91px':'55px'}}><DoneIcon style={{color:'#007f85',width:mobile?'18px':'28px',height:'28px',strokeWidth:'3px',marginRight:'16px'}}/>Collaterals</Typography>
								  </Grid>
                                 
								  <Grid item lg={12} sx={{width:'300px'}}>
                    {basic2?<>
                    <Grid item lg={9.8} xs={9.8} sx={{margin:'auto'}}>
                        <Typography sx={{fontSize:'14px',fontWeight:'500',color:'#000',marginTop:'26px',textAlign:'initial'}}> <CircleIcon style={{width:mobile?'6px':'8px',height:mobile?'6px':'8px',marginRight:'8px'}}/>In addition to connecting you with a literary agent, editorial support is elevated to include line editing, which involves in-depth editing of your manuscript to improve clarity, flow, and overall writing quality.</Typography>
                    </Grid>
                    <Grid item lg={9.8} xs={9.8} sx={{margin:'auto'}}>
                        <Typography sx={{fontSize:'14px',fontWeight:'500',color:'#000',marginTop:'16px',textAlign:'initial'}}> <CircleIcon style={{width:mobile?'6px':'8px',height:mobile?'6px':'8px',marginRight:'8px'}}/>Collateral services in this package are designed to position you as a market-savvy author. This includes a professionally designed marketing pitch deck including:
      ~ a synopsis tailored to publisher guidelines
      ~ competitor analysis for three similar titles in your genre
      ~ a detailed target audience     analysis and
      ~ market analysis.</Typography>
                    </Grid>
                    <Grid item lg={9.8} xs={9.8} sx={{margin:'auto'}}>
                        <Typography sx={{fontSize:'14px',fontWeight:'500',color:'#000',marginTop:'16px',textAlign:'initial'}}> <CircleIcon style={{width:mobile?'6px':'8px',height:mobile?'6px':'8px',marginRight:'8px'}}/>These collaterals will help you effectively pitch your book to publishers, understand your competitive landscape, and refine your marketing strategy, ultimately increasing your book's market potential.</Typography>
                    </Grid>
                    <Grid item lg={12}>
                        <Button onClick={handleClick2} sx={{fontWeight:'600',fontSize:mobile?'10px':'14px',fontFamily:'Inter',color:'#007f85',textTransform:'none',marginTop:'26px'}}>See less</Button>
                    </Grid></>:
                    <Grid item lg={12}>
                        <Button onClick={handleClick2} sx={{fontWeight:'600',fontSize:mobile?'10px':'14px',fontFamily:'Inter',color:'#007f85',textTransform:'none',marginTop:mobile?'20px':'26px'}}>See more</Button>
										  </Grid>}
										  </Grid>
                    <Grid item lg={12}>
                        <CustomButton variant='contained' onClick={()=>handleGetNowClick3()}   sx={{backgroundColor:'#007f85',color:'#fff',textTransform:'none',width:'220px',padding:'12px 20px',marginTop:'20px',fontSize:mobile?'14px':'16px',fontWeight:'500',marginBottom:'56px',borderRadius:'8px'}}>Get Now</CustomButton>
                    </Grid>
                </Grid>
						  </Grid>
						  <Dialog open={openForm3} onClose={() => setOpenForm3(false)}>
						  <DialogTitle sx={{ display: 'flex', justifyContent: 'end', height: '5px' }} ><Close onClick={() => handleClose3()} /></DialogTitle>
						
							  <DialogContent>
							  {loading ? <>
									  <DialogContentText id="alert-dialog-description">
										  <Grid container lg={12} sx={{justifyContent:'center'}}>
											  <Grid item lg={12} sx={{ alignItems:'center',display:'flex',justifyContent:'center'}}>
											  <CheckCircleOutlineIcon style={{width:"47px",height:'47px',color:"#007F85"}} /> 
											  </Grid>
											  <Grid item lg={12}>
											  <Typography sx={{color: '#000',

textAlign: 'center',
fontFamily: 'Inter',
											  fontSize:'20px',
fontStyle: 'normal',
fontWeight: 500,
											  lineHeight: 'normal'
												  }}>Your request has been submitted.</Typography>	
												  <Typography sx={{color: '#000',

textAlign: 'center',
fontFamily: 'Inter',
											  fontSize:'16px',
fontStyle: 'normal',
fontWeight: 400,
											  lineHeight: '22.4px'
										  }}>Our team will contact you soon for further processing.</Typography>
												  </Grid>
										  </Grid>
           
            </DialogContentText></> :
									  <>
										  <Typography sx={{color: '#000',marginTop:'26px',

textAlign: 'center',
fontFamily: 'Inter',
											  fontSize:'20px',
fontStyle: 'normal',
fontWeight: 500,
											  lineHeight: 'normal'
										  }}>To continue, please enter your phone number.</Typography>
										  <Grid container lg={12} sx={{marginTop:'26px'}}>
											  <Grid item lg={8}>
											  <TextField placeholder="Phone Number" variant="outlined" size="small" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} style={{width:mobile?"273px":"295px",height:"39px",borderRadius: '8px',
border: '1px solid #007F85'}} />
   			  
											  </Grid>
											  <Grid item lg={4}>
	 <CustomButton variant="contained" color="primary" onClick={()=>handleEmail(60000)}  sx={{backgroundColor:'#007f85',height:"39px",color:'#fff',textTransform:'none',width:mobile?"273px":'180px',padding:'12px 20px',marginTop:mobile?'36px':'0px',fontSize:mobile?'14px':'16px',fontWeight:'500',marginBottom:'0px',borderRadius:'8px'}}>
													  
													  {loading ? <CircularProgress style={{ color: "#FFFFFF" }} />: 'Continue'} 
												  </CustomButton>	  
												  </Grid>
										  </Grid>
										 
									
									  </>}
  </DialogContent>
</Dialog>
            </Item>
      </Stack>
      </Grid>
      </Grid>
      <Footer/>
    </>
   
  );
}

export default Pro