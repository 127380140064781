import { Grid, Typography } from '@mui/material'
import React,{useEffect} from 'react'
import Footer from './Footer';
import Header from './Header';
import Mobileheader from './Mobileheader';
import 'typeface-inter';
// import { useDevice } from './Responsive/devices'
import useMediaQuery from '@mui/material/useMediaQuery';
const Privacy = () => {
	const mobile = useMediaQuery('(max-width:600px)');
        useEffect(() => {
		window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
	  }, []);
  return (
    <div>
       {mobile?<Mobileheader/>:<Header/>}
      <Grid container lg={12} xs={10.8} sx={{margin:mobile?'auto':'0px'}} >
        <Grid container lg={12} xs={12} sx={{margin:'auto'}}>
        <Grid item lg={10} xs={10}  sx={{margin:'auto'}}>
       <Typography variant={mobile?"h6":'h6'} sx={{textAlign:'initial',fontFamily:'Inter',fontSize:mobile?'20px':'36px',marginTop:mobile?'40px':'35px',}}>Privacy Policy</Typography> 
        </Grid>
        <Grid item lg={10} xs={10}  sx={{margin:'auto'}}>
            <Typography sx={{marginTop:mobile?'16px':"18px",fontFamily:'Inter',fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'28px'}}>
            This privacy policy sets out how Hubhawks Marketing India Private Limited, a Private Limited
Company registered under the Companies Act, 2013 having its registered office at Unit No. 125, 1 st
Floor, Vipul Trade Centre, Sector 48, Gurgaon, Haryana - 122018 proprietor of a website having
domain name and style of “Slushie” (“Slushie”, “Platform”, “We”, “Our” or “Us”), uses and
protects any information of the viewer, end-users and customers/author who are registered with
Slushie. For the purpose of this privacy policy, the terms “Users”, “You”, or “Your” refer to the
registered customer/user and/or viewer of the website.</Typography>
            </Grid>
            <Grid item lg={10} xs={10}  sx={{margin:'auto'}}>
            <Typography sx={{marginTop:mobile?'12px':"18px",fontFamily:'Inter',fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'28px',textAlign:'initial'}}>
            We value You as a customer and respect Your right to privacy. In the process of operating the
website, We may become aware of the information relating to You. We are strongly committed to
protecting Your privacy online and have taken steps to protect such information. To aid Us in
protecting Your privacy, You should maintain secrecy of Your login ID and password, if any,
provided to you in connection with your account with Slushie (located at https://slushie.in/). We
retain and process your data submitted by You, in accordance with the Information Technology
Act, 2000 and Rules made thereunder, Digital Personal Data Protection Act, 2023 as well as under
this Privacy Policy. By accessing and/or registering on the Platform, You signify Your acceptance
to this Privacy Policy. If you object to Your Information being used in the manner prescribed by
law or under this Privacy Policy, kindly refrain from continuing to use and registering on the
Platform.</Typography>
            </Grid>
           
           
            
            
      
      </Grid>
      <Grid container lg={10} xs={10} sx={{margin:'auto'}}>
     <Grid item lg={12} xs={12} >
       <Typography  sx={{textAlign:'initial',fontFamily:'Inter',FontSize:mobile?'16px':'18px',fontWeight:mobile?'600':"600",lineHeight:mobile?'24px':"24px",marginTop:mobile?'36px':'30px'}}><span style={{fontWeight:'400'}}>1.</span> Collection of Your Personal Information:</Typography> 
       </Grid>
       <Grid item lg={12} xs={12} >
       <Typography   sx={{marginTop:mobile?'20px':"20px",fontFamily:'Inter',fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'30px',textAlign:'initial',fontWeight:mobile?'700px':'700px',}}>
        &nbsp;<u>Information Collected upon registration:&nbsp;</u>  We collect Name, Email ID and Date of Birth of the
User provided to Us by You at the time of registration or signing up. We also reserve the right to
collect supplementary information in the event that You register or sign up using Your Social
Media Account, sensitive financial information required for the payment mechanism in the event
You register/access Our premium version of the Platform and any other information provided to
Us by You through the Platform. This personal information will be used to automatically log in as
a user/member of Slushie. If You contact us, We may keep Your message, email, and/or Your
contact information to respond to Your message. The details are used only for the specific purpose
of fulfilling your requests, improving our services, and contacting you.</Typography>
  </Grid>     
<Grid item lg={12} >
        <Typography sx={{marginTop:mobile?'12px':"20px",fontFamily:'Inter',marginLeft:mobile?'0px':"0px",fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'28px',textAlign:'initial',fontWeight:'700px',}}>
        2. &nbsp;<b>Process of Collection of Information:</b> We shall collect Your personal information when You provide Us the information while registering
or signing up on the Platform. We also obtain the information about You, from any e-mails, letters,
or any other mode of communication which You send to Us.</Typography>
</Grid>
<Grid item lg={12} >
        <Typography sx={{marginTop:mobile?'12px':"20px",fontFamily:'Inter',marginLeft:mobile?'0px':"0px",fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'28px',textAlign:'initial',fontWeight:'700px',}}>
        3. &nbsp;<b>Consent:</b>When You furnish Us with Your personal information during the registration process, or through
any messages, or while visiting the Platform, it implies that You are granting Your explicit consent
for the collection of Your personal information. We may share or disclose Your information with
Your express consent.</Typography>
</Grid>
<Grid item lg={12} >
        <Typography sx={{marginTop:mobile?'12px':"10px",fontFamily:'Inter',marginLeft:mobile?'0px':"0px",fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'28px',textAlign:'initial',fontWeight:'700px',}}>
        4. &nbsp;<b>Use of Your Personal Information:</b> By using the Platform and/or registering on the Platform, You hereby authorize Us to contact You
via e-mails and/or messages on the contact details so provided to furnish You with information
with regards to the services of Slushie. We require this information to understand Your needs and
provide You better service, and in particular for the following reasons:</Typography>
</Grid>
<Grid item lg={12}>
        <Typography sx={{marginTop:mobile?'12px':"10px",marginLeft:mobile?'0px':"0px",fontFamily:'Inter',fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'28px',textAlign:'initial',fontWeight:'700px',}}>
       4.1 &nbsp;<u>Your use of the Platform:</u>  To analyze Your manuscript, provide services related to publication of
the manuscript and/or create, maintain and update the User accounts on Platform.</Typography>
</Grid>
<Grid item lg={12} >
        <Typography sx={{marginTop:mobile?'12px':"10px",marginLeft:mobile?'0px':"0px",fontFamily:'Inter',fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'30px',textAlign:'initial',fontWeight:'400px',}}>
       4.2 &nbsp; <u>Communications:</u>To respond to Your queries, request for information, processing your
manuscript and helping You choose the chapters that will go live for readers on various social
media accounts, and to keep You updated about the status of the status of votes on Your
manuscript to process it for publication.</Typography>
</Grid>

 
 
 
  
 



<Grid item lg={12} >
        <Typography sx={{marginTop:mobile?'12px':"10px",marginLeft:mobile?'0px':"0px",fontFamily:'Inter',fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'28px',textAlign:'initial',fontWeight:mobile?'700px':'400px',}}>
        4.3 &nbsp; <u>Safety:</u>We reserve the right to use, within the bounds of applicable laws and regulations, for the
purpose of enhancing safety, including, but not limited to, the following objectives:  </Typography>
</Grid> 
<Grid item lg={12} >
        <Typography sx={{marginTop:mobile?'5px':"10px",marginLeft:mobile?'0px':"0px",fontFamily:'Inter',fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'28px',textAlign:'initial',fontWeight:'400px',}}>
      4.3.1. &nbsp;&nbsp;&nbsp;Verify identity of Our Users; </Typography>
</Grid> 
<Grid item lg={12} >
        <Typography sx={{marginTop:mobile?'5px':"10px",marginLeft:mobile?'0px':"0px",fontFamily:'Inter',fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'28px',textAlign:'initial',fontWeight:'400px',}}>
      4.3.2. &nbsp;&nbsp;&nbsp;Prevent and investigate fraud and unauthorized activities; </Typography>
</Grid>
<Grid item lg={12} >
        <Typography sx={{marginTop:mobile?'5px':"10px",marginLeft:mobile?'0px':"0px",fontFamily:'Inter',fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'28px',textAlign:'initial',fontWeight:'400px',}}>
      4.3.3. &nbsp;&nbsp;&nbsp;Defend against claims and other liabilities; </Typography>
</Grid>
<Grid item lg={12} >
        <Typography sx={{marginTop:mobile?'5px':"10px",marginLeft:mobile?'0px':"0px",fontFamily:'Inter',fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'28px',textAlign:'initial',fontWeight:'400px',}}>
      4.3.4. &nbsp;&nbsp;&nbsp;Manage other copyright infringement risks and to comply with applicable laws; </Typography>
</Grid>
<Grid item lg={12} >
        <Typography sx={{marginTop:mobile?'5px':"10px",marginLeft:mobile?'0px':"0px",fontFamily:'Inter',fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'28px',textAlign:'initial',fontWeight:'400px',}}>
      4.3.5. &nbsp;&nbsp;&nbsp;Protect Our and Our Users’ rights and interest, defend Ourselves, and respond to law
enforcement, other legal authorities, and requests that are part of a legal process, comply
with applicable security and other such due diligence laws and requirements; </Typography>
</Grid>
<Grid item lg={12} >
        <Typography sx={{marginTop:mobile?'5px':"10px",marginLeft:mobile?'0px':"0px",fontFamily:'Inter',fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'28px',textAlign:'initial',fontWeight:'400px',}}>
      4.3.6. &nbsp;&nbsp;&nbsp;Operate Our business as per lawful business purposes and as permitted by laws of India. </Typography>
</Grid>
<Grid item lg={12} xs={12}  >
        <Typography sx={{marginTop:mobile?'20px':'20px',fontFamily:'Inter',fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'28px',textAlign:'initial',fontWeight:mobile?'400':'400px',}}>
        4.4 &nbsp;<u>Other Business Purpose and Compliance:</u> To maintain, improve and measure the effectiveness of
the Platform, activities, and services provided to our Users, to maintain record of the
communication between Users and Platform, to monitor or record e-mails, messages and other
communications with Our customer service team and other representatives, as well as Platform
communications for quality control, training.
          </Typography>
</Grid>
<Grid item lg={12} xs={12}>
        <Typography sx={{marginTop:mobile?'12px':'10px',marginLeft:mobile?'0px':"0px",fontFamily:'Inter',fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'28px',textAlign:'initial',fontWeight:mobile?'':'400px',}}>
       5. &nbsp; <b>Sharing of Information to a third party:</b>By using Our Platform, You hereby grant Your consent for Us to share the information You
provide, with third-party service providers, such as publishers, when such disclosure is deemed
necessary to facilitate the provision of the services You intend to access on or through the
Platform. </Typography>
</Grid>
<Grid item lg={12} xs={12}  >
        <Typography sx={{marginLeft:mobile?'0px':"0px",fontFamily:'Inter',fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'28px',textAlign:'initial',fontWeight:mobile?'400':'400px',marginTop:mobile?'12px':'10px'}}>
        6. &nbsp; <b>Security:</b> We are committed to ensure that Your information is secure. We employ a range of security
measures to safeguard user information from unauthorized access, disclosure, alteration,
destruction, or exposure to third parties. We do not participate in the sale, rental, or trade of
personal information of Our Users to third party (public or private). Please understand that while
We strive to protect Your personal data against potential risks and exposures, there is no absolute
security in the internet sphere. You understand that, We cannot guarantee the security of
transmission of Your data and any transmission is at Your own risk.</Typography>
</Grid>
<Grid item lg={12}  xs={12} >
        <Typography sx={{marginLeft:mobile?'0px':"0px",fontFamily:'Inter',fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'28px',textAlign:'initial',fontWeight:mobile?'400':'400px',marginTop:mobile?'12px':'10px'}}>
        7. &nbsp; <b>Changes to the Privacy Policy:</b> Slushie reserves the right, at its sole discretion, to amend or revise this privacy policy at any time
without prior notice. Any such changes and/or modifications will take immediate effect upon
being posted or published on the Platform. Your continued use of the Platform after the posting of
changes and/or modifications will signify your acceptance of the updated privacy policy.</Typography>
</Grid>
<Grid item lg={12}xs={12} >
        <Typography sx={{marginLeft:mobile?'0px':"0px",fontFamily:'Inter',fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'28px',textAlign:'initial',fontWeight:mobile?'400px':'400px',marginTop:mobile?'12px':'10px'}}>
        8.&nbsp; <b>Disclaimer</b> By using and accessing the platform, and by availing the services or by otherwise giving Us Your
information, You will be deemed to have read, understood and agreed to the practices and policies
outlined in this Privacy Policy and agree to be bound by the Privacy Policy. You hereby consent to
Our collection, use and sharing, disclosure of Your information as described in this Privacy Policy.
We reserve the right to change, modify, add or delete portions of the terms of this privacy policy,
at our sole discretion, at any time. If You do not agree with this Privacy Policy at any time, do not
visit the website, use any of the Services, access the Platform or give Us any of your information. If
You use the services on behalf of someone else (such as an author of the manuscript) You
represent that You are (i) authorized by such individual to accept this Privacy Policy on such
individual’s behalf, and (ii) have obtained the consent of or are authorised to provide consent on
behalf of such individual to Our collection, use and disclosure of such individual’s information in
accordance with the terms contained herein. You may note that this Privacy Policy is based on the
applicable laws of India and good data privacy practices adopted by Slushie in India and may be
found deficient with respect to certain privacy laws of some other countries. By visiting the
Platform You agree to be bound by this privacy policy. If You do not agree with the terms
contained herein, please do not use or access our platform or avail any services on the platform. By
mere use of the electronic media platform, You expressly consent to Our use and disclosure of
your personal information in accordance with this privacy policy.</Typography>
</Grid>

<Grid item lg={12}xs={12} >
        <Typography sx={{marginLeft:mobile?'0px':"0px",fontFamily:'Inter',fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'28px',textAlign:'initial',fontWeight:mobile?'400px':'400px',marginTop:mobile?'12px':'10px'}}>
        9.&nbsp; <b>Limited Liability:</b> This clause limits the liability of Slushie and its parent, affiliates, related companies, officers,
directors, employees, agents, representatives, partners and licensors. While we make diligent efforts
to safeguard your personal data from potential risks and vulnerabilities, it is important to
acknowledge that no internet security measures can provide absolute certainty. You understand and
acknowledge that we cannot guarantee the security of the transmission of your data, and any such
transmission is undertaken at your own risk. Notwithstanding anything contained herein or
elsewhere, in case of a non-paid User i.e., the User not having access to the premium version of the
Platform, We completely disclaim any kind of liability whether under this Privacy Policy or
applicable law. In case of paid Users i.e., Users having access to the premium version of the
Platform, Slushie shall be held liable only for its breach of this Privacy Policy, in accordance with
the governing laws. Slushie&#39;s total liability howsoever arising due to (i) Your usage of the Website,
or (ii) any services availed, or (iii) otherwise, arising from or relating to this Privacy Policy, is limited
to an amount not exceeding 100% of the fee last paid by the User for the utilization of the Services
provided via the platform.</Typography>
</Grid>
<Grid item lg={12}xs={12} >
        <Typography sx={{marginLeft:mobile?'0px':"0px",fontFamily:'Inter',fontSize:mobile?'14px':'16px',lineHeight:mobile?'20px':'28px',textAlign:'initial',fontWeight:mobile?'400px':'400px',marginTop:mobile?'12px':'10px'}}>
        10.&nbsp; <b>Contact Us:</b> If You have any questions or concerns about Our use of Your personal information, please contact
Us at:   <a href="mailto:admin@slushie.in">admin@slushie.in</a>.</Typography>
</Grid>
</Grid>
</Grid>  

     <Footer/>

    </div>
  )
}

export default Privacy